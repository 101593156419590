import AnalyticsDataGridColDefs, {
  displayedByDefault,
  hiddenByDefault,
} from "./AnalyticsDataGridColDefs";
import {
  DataGridPremium,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
  ToolbarPropsOverrides,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  Dispatch,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { GET_LIST, GET_PLAYERS } from "../../api/keys";
import {
  SEARCH_PARAMS_LIST_ID,
  SEARCH_PARAMS_PLAYER_ID,
} from "../../constants";
import { SEVERITY_ERROR, useAlertContext } from "../../contexts/AlertContext";
import { useList, usePlayerDatabase } from "../../api/queries";
import { useMediaQuery, useTheme } from "@mui/material";

import { CustomGridToolbar } from "../../components/CustomGridToolbar";
import { EmptyStateOverlay } from "../../components/EmptyStateOverlay";
import { Player } from "../../api/types";
import { getUniqueKeyPlayer } from "../../utils/uniqueKey";
import { useDebounce } from "use-debounce";
import { useListContext } from "../../contexts/ListContext";
import useLocalStorage from "react-use-localstorage";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

interface AnalyticsDataGridProps {
  viewMode: string;
  setViewMode: Dispatch<string>;
}

const VIEW_MODE_ANALYTICS = "analytics";

const DEFAULT_DENSITY = "compact";
const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 30;

const initialColumnVisibilityModel: GridColumnVisibilityModel = {};
displayedByDefault.map(
  (col: GridColDef) => (initialColumnVisibilityModel[col.field] = true)
);
hiddenByDefault.map(
  (col: GridColDef) => (initialColumnVisibilityModel[col.field] = false)
);

const initialGridState = {
  columns: {
    columnVisibilityModel: initialColumnVisibilityModel,
  },
  density: DEFAULT_DENSITY,
  pagination: {
    paginationModel: { pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_PAGE },
  },
  pinnedColumns: { left: ["name", "team", "birthdate"], right: [] },
} as GridInitialState;

// Must declare toolbar component to outside main component to remedy the issue of search bar unfocusing each time
// input is updated. This was due to the Toolbar rerendering each time the value changed which causes the focus to be lost
//
// https://github.com/mui/mui-x/issues/9580
const _CustomGridToolbar = (props: ToolbarPropsOverrides) =>
  CustomGridToolbar(props);

export default function AnalyticsDataGrid(props: AnalyticsDataGridProps) {
  // HOOKS
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // CONTEXTS

  const { listInContext, setListInContext } = useListContext();
  const { setAlertOptions } = useAlertContext();

  // REFs
  const apiRef = useGridApiRef();

  // VARIABLES
  const listId = searchParams.get(SEARCH_PARAMS_LIST_ID);
  const loadList = listId !== undefined && listId !== null;
  const columnDefs = AnalyticsDataGridColDefs({ colDefs: ["*"] });

  // STATES
  const [rowCountState, setRowCountState] = useState(0);
  // GRID STATES
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [dataGridState, setDataGridState] =
    useState<GridInitialState>(initialGridState);
  const [density, setDensity] = useState<GridDensity>(DEFAULT_DENSITY);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();
  const [pinnedColumns, setPinnedColumnFields] =
    useState<GridPinnedColumnFields>({
      left: [],
      right: [],
    });
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>();
  const [debouncedFilterModel] = useDebounce(filterModel, 500);
  const [debouncedSortModel] = useDebounce(sortModel, 500);

  // LOCAL STORAGE
  const [localStorageDataGridState, setLocalStorageDataGridState] =
    useLocalStorage("player_database_state", JSON.stringify(dataGridState));
  const gridState = JSON.parse(
    localStorageDataGridState ? localStorageDataGridState : "{}"
  );

  // QUERIES
  const listQueryResult = useList(
    "analytics",
    loadList,
    Number(listId),
    debouncedFilterModel,
    debouncedSortModel,
    setListInContext
  );
  const playersQueryResult = usePlayerDatabase(
    !loadList,
    VIEW_MODE_ANALYTICS,
    paginationModel?.page || DEFAULT_PAGE,
    paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    debouncedFilterModel,
    debouncedSortModel
  );

  // VARIABLES
  const data = useCallback(() => {
    return loadList
      ? listQueryResult?.data?.players?.data || []
      : playersQueryResult?.data?.data || [];
  }, [
    listQueryResult?.data?.players?.data,
    loadList,
    playersQueryResult?.data?.data,
  ]);

  // FUNCTIONS
  const saveDataGridState = useCallback(() => {
    // console.debug("saving datagrid state");
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      setLocalStorageDataGridState(JSON.stringify(currentState));
    }
  }, [apiRef, setLocalStorageDataGridState]);

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => setColumnVisibilityModel(model),
    [setColumnVisibilityModel]
  );

  const onDensityChange = useCallback(
    (density: GridDensity) => setDensity(density),
    [setDensity]
  );

  const onFilterModelChange = useCallback(
    (model: GridFilterModel, details: GridCallbackDetails) => {
      //   console.debug(details);
      setFilterModel(model);
    },
    [setFilterModel]
  );

  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel, details: GridCallbackDetails) => {
      setPaginationModel(model);
      queryClient.invalidateQueries([GET_PLAYERS, GET_LIST]);
    },
    [setPaginationModel, queryClient]
  );

  const onPinnedColumnFieldsChange = useCallback(
    (pinnedColumns: GridPinnedColumnFields) =>
      setPinnedColumnFields(pinnedColumns),
    [setPinnedColumnFields]
  );

  const onProcessRowUpdateError = useCallback(
    (error: Error) => {
      setAlertOptions({
        message: `failed to update playerInContext: ${error}`,
        severity: SEVERITY_ERROR,
      });
    },
    [setAlertOptions]
  );

  const onRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      // console.debug(selectionModel);
      setSelectionModel(selectionModel);
    },
    []
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel, details: GridCallbackDetails) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const showPlayerProfileModal = useCallback(
    (
      params: GridRowParams,
      event: MuiEvent<React.MouseEvent>,
      details: GridCallbackDetails
    ) => {
      const p = params.row as Player;
      searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(p.id));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  // USEFFECTS
  useLayoutEffect(() => {
    // Merge defaults
    // console.debug(gridState);
    if (
      gridState?.pinnedColumns?.left &&
      gridState?.pinnedColumns?.left?.length === 0
    ) {
      const initialPinnedLeftCols = initialGridState?.pinnedColumns?.left;
      const existingPinnedLeftCols = gridState.pinnedColumns.left;
      gridState.pinnedColumns.left = initialPinnedLeftCols?.concat(
        existingPinnedLeftCols
      );
    }

    if (
      gridState?.columns?.columnVisibilityModel &&
      Object.keys(gridState?.columns?.columnVisibilityModel).length === 0
    ) {
      gridState.columns.columnVisibilityModel = initialColumnVisibilityModel;
    }

    setColumnVisibilityModel(gridState?.columns?.columnVisibilityModel);
    setDensity(gridState?.density);
    setFilterModel(gridState?.filter?.filterModel);
    setPaginationModel(gridState?.pagination?.paginationModel);
    setPinnedColumnFields(gridState?.pinnedColumns);
    setSortModel(gridState?.sorting?.sortModel);
    setDataGridState(gridState);

    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveDataGridState);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveDataGridState);
      window.removeEventListener("blur", saveDataGridState);
      saveDataGridState();
    };
    // eslint-disable-next-line
  }, []);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  useEffect(() => {
    setRowCountState((prevRowCountState) => {
      if (listInContext && listQueryResult?.data) {
        return listQueryResult.data.players.total_data_count;
      } else if (playersQueryResult?.data) {
        // Covers the edge case where no actual data is returned
        if (playersQueryResult.data?.data.length === 0) {
          return 0;
        }

        return playersQueryResult.data.total_data_count;
      } else {
        return prevRowCountState;
      }
    });
  }, [
    listInContext,
    playersQueryResult.data,
    listQueryResult.data,
    setRowCountState,
  ]);

  return (
    <DataGridPremium
      apiRef={apiRef}
      aria-label={listInContext?.name || ""}
      autosizeOnMount={true}
      // checkboxSelection={props.checkboxSelection}
      columns={columnDefs}
      columnVisibilityModel={columnVisibilityModel}
      density={density as GridDensity}
      // disable reordering for now because it's causing an error with react-dnd as described here: https://stackoverflow.com/questions/72278695/mui-datagridpro-crashes-when-reordering-columns-by-dragdrop-when-already-using
      disableColumnReorder={true}
      disableRowSelectionOnClick={true}
      getRowId={getUniqueKeyPlayer}
      filterModel={filterModel}
      hideFooter={!data || rowCountState === 0}
      hideFooterSelectedRowCount
      ignoreDiacritics // https://mui.com/x/react-data-grid/filtering/quick-filter/#ignore-diacritics-accents
      initialState={dataGridState}
      isRowSelectable={(params: GridRowParams) => {
        //   console.debug(params);
        return params.row.id !== null && params.row.id !== undefined;
      }}
      keepNonExistentRowsSelected // https://mui.com/x/react-data-grid/selection/#usage-with-server-side-pagination
      key="playerInContext-analytics-database"
      loading={playersQueryResult.isFetching || listQueryResult.isFetching}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onDensityChange={onDensityChange}
      onPaginationModelChange={onPaginationModelChange}
      onFilterModelChange={onFilterModelChange}
      onPinnedColumnsChange={onPinnedColumnFieldsChange}
      onProcessRowUpdateError={onProcessRowUpdateError}
      onRowClick={showPlayerProfileModal}
      onRowSelectionModelChange={onRowSelectionModelChange}
      onSortModelChange={onSortModelChange}
      pageSizeOptions={[DEFAULT_PAGE_SIZE, 100, 1000, 10000, 100000]}
      pagination={!loadList}
      paginationModel={paginationModel}
      paginationMode="server"
      pinnedColumns={pinnedColumns}
      rows={data()}
      rowCount={rowCountState}
      rowSelectionModel={selectionModel}
      slots={{
        noResultsOverlay: () => (
          <EmptyStateOverlay height="80vh" content="No players found" />
        ), // The no-results overlay is displayed when the Data Grid has no results after filtering.
        noRowsOverlay: () => (
          <EmptyStateOverlay height="80vh" content="No players found" />
        ), // The no-rows overlay is displayed when the Data Grid has no rows.
        toolbar: _CustomGridToolbar,
      }}
      slotProps={{
        loadingOverlay: {
          variant: "linear-progress",
          noRowsVariant: "skeleton",
        },
        toolbar: {
          viewMode: props.viewMode,
          setViewMode: props.setViewMode,
          quickFilterProps: {
            InputProps: {
              sx: {
                width: isScreenSmall ? "100vw" : "30vw",
              },
            },
          },
        },
      }}
      sortingMode="server"
      sortModel={sortModel}
      sx={{
        border: 0,
        "& .MuiButton-text": {
          fontSize: isScreenSmall ? 13 : 16,
        },
        "& .MuiDataGrid-cell": {
          padding: 0,
        },
        //   "& .MuiDataGrid-footerContainer": {
        //     display: "flex",
        //     justifyContent: "left",
        //   },
      }}
    />
  );
}
