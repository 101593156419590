import { Box, Divider, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import { useDeleteNoteMutation, useUpdateNoteMutation } from "../api/mutations";
import { useEffect, useRef, useState } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Note } from "../api/types";
import TimeAgo from "javascript-time-ago";
import Typography from "@mui/material/Typography";
import UpdateDialog from "./dialogs/UpdateDialog";
import { formatDateTimeWithSecondsToLocal } from "../utils/dates";
import moment from "moment";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useThemeContext } from "../contexts/CustomThemeContext";

export type NoteCardProps = {
  note: Note;
  actionBy: string | undefined;
  highlighted?: boolean;
};

export default function NoteCard(props: NoteCardProps) {
  const { theme } = useThemeContext();
  const { email } = useOktaAuthContext();
  const { playerInContext } = usePlayerContext();
  const scrollRef = useRef<HTMLTableRowElement>(null);
  const timeAgo = new TimeAgo("en-US");

  const [mutateNoteDialogOpen, setMutateNoteDialogOpen] = useState(false);
  const boxShadow = props.highlighted
    ? `2px 2px ${theme.palette.secondary.main};`
    : `2px 2px ${theme.palette.background.paper};`;

  // MUTATIONS
  const deletePlayerNote = useDeleteNoteMutation();
  const updateNote = useUpdateNoteMutation();

  useEffect(() => {
    if (props.highlighted) {
      scrollRef.current?.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
        block: "end",
        inline: "nearest",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.highlighted, scrollRef.current]);

  const createdAt = formatDateTimeWithSecondsToLocal(
    props.note.created_at,
    false
  );
  const createdAtTimeAgo = timeAgo.format(
    moment.utc(props.note.created_at).toDate()
  );

  return (
    <Box mb={1} sx={{ width: "95%" }}>
      <UpdateDialog
        content={props.note.content}
        open={mutateNoteDialogOpen}
        rows={4}
        title={`Update note for ${playerInContext?.first_name} ${playerInContext?.last_name}`}
        verbage={"Update"}
        setOpen={setMutateNoteDialogOpen}
        yesAction={(newValue) => {
          const { created_by, created_at, updated_at, ...noCreatedBy } =
            props.note;
          updateNote.mutate({
            ...noCreatedBy,
            ...{
              content: newValue,
              updated_by: email,
            },
          } as Note);
          setMutateNoteDialogOpen(false);
        }}
      />

      <Card
        variant="outlined"
        ref={props.highlighted ? scrollRef : null}
        sx={{
          boxShadow: boxShadow,
          width: "100%",
          minWidth: 275,
        }}
      >
        <CardContent sx={{ whiteSpace: "pre-line" }}>
          <Typography variant="body1">{props.note.content}</Typography>
        </CardContent>

        <Divider />

        <CardActions disableSpacing sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography
            noWrap={true}
            marginRight="auto"
            variant="caption"
            color="text.secondary"
          >
            {`${props.actionBy} | ${createdAt} (${createdAtTimeAgo})`}
          </Typography>

          <IconButton
            color="primary"
            onClick={() => setMutateNoteDialogOpen(true)}
          >
            <MuiTooltip title={"Edit"}>
              <EditIcon fontSize="small" />
            </MuiTooltip>
          </IconButton>

          <IconButton
            color="primary"
            onClick={() => {
              deletePlayerNote.mutate(props.note.id);
            }}
          >
            <MuiTooltip title={"Delete"}>
              <DeleteIcon fontSize="small" />
            </MuiTooltip>
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  );
}
