import {
  ANALYTICS_ROLE,
  COACHING_ROLE,
  OPERATIONS_ROLE,
  SCOUTING_ROLE,
} from "../constants";
import {
  COACHES_CORNER_ROUTE,
  OPERATIONS_ROOT_ROUTE,
  ROOT_ROUTE,
  SCOUTING_ROOT_ROUTE,
} from "../routes";
import { createContext, useContext, useEffect, useState } from "react";

import CoachesCornerPage from "../pages/coaches_corner";
import OperationsPage from "../pages/operations";
import Scouting from "../pages/scouting";
import { useAuth } from "../api/queries";
import { useConstantsContext } from "./ConstantsContext";
import { useOktaAuthContext } from "./OktaAuthContext";
import { useRollbarPerson } from "@rollbar/react";

interface User {
  primaryClub: string;
  primaryRole: string;
  primaryTeam: string;
  homePagePath: string;
  HomePage: JSX.Element;
  clubs: string[];
  roles: string[];
  permissions: string[];
  teams: string[];
}

const defaultState = {
  primaryClub: "",
  primaryRole: "",
  primaryTeam: "",
  HomePage: <CoachesCornerPage />,
  homePagePath: "/",
  clubs: [],
  roles: [],
  permissions: [],
  teams: [],
};

const UserContext = createContext<User>(defaultState);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // CONTEXT
  const { name, email } = useOktaAuthContext();
  const { users } = useConstantsContext();
  const auth = useAuth().data;

  // Rollbar configuration should already be set up from <RollbarProvider /> in App.tsx
  useRollbarPerson({ name: name, email: email });

  // STATE
  const [primaryClub, setPrimaryClub] = useState<string>("");
  const [primaryRole, setPrimaryRole] = useState<string>("");
  const [primaryTeam, setPrimaryTeam] = useState<string>("");
  const [homePage, setHomePage] = useState<JSX.Element>(<CoachesCornerPage />);
  const [homePagePath, setHomePagePath] = useState<string>("");
  const [permissions, setPermissions] = useState<string[]>([]);
  const [clubs, setClubs] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [teams, setTeams] = useState<string[]>([]);

  // VARIABLES
  const route = window.location.pathname;

  // console.debug(route);
  // console.debug(usersQuery.data);
  // console.debug(name);
  // console.debug(email);

  useEffect(() => {
    // console.debug(user);

    if (auth) {
      setPrimaryClub(auth.permissions.primary_club);
      setPrimaryRole(auth.permissions.primary_role);
      setPrimaryTeam(auth.permissions.primary_team);
      setClubs(auth.permissions.clubs);
      setRoles(auth.permissions.roles);
      setTeams(auth.permissions.teams);
      setPermissions(auth.permissions.permissions);

      if (primaryRole === OPERATIONS_ROLE || primaryRole === ANALYTICS_ROLE) {
        setHomePagePath(OPERATIONS_ROOT_ROUTE);
        setHomePage(<OperationsPage />);
      } else if (primaryRole === COACHING_ROLE) {
        setHomePagePath(COACHES_CORNER_ROUTE);
        setHomePage(<CoachesCornerPage />);
      } else if (primaryRole === SCOUTING_ROLE) {
        setHomePagePath(SCOUTING_ROOT_ROUTE);
        setHomePage(<Scouting />);
      } else {
        setHomePagePath(ROOT_ROUTE);
        setHomePage(<OperationsPage />);
      }
    } else {
      setHomePagePath(COACHES_CORNER_ROUTE);
      setHomePage(<CoachesCornerPage />);
    }
  }, [
    auth,
    email,
    name,
    route,
    users,
    primaryRole,
    setHomePage,
    setHomePagePath,
  ]);

  //   console.debug(`home page path: ${homePagePath}`);

  return (
    <UserContext.Provider
      value={{
        primaryClub: primaryClub,
        primaryRole: primaryRole,
        primaryTeam: primaryTeam,
        HomePage: homePage,
        homePagePath: homePagePath,
        clubs: clubs,
        roles: roles,
        teams: teams,
        permissions: permissions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
