import { League, User } from "../api/types";
import { createContext, useContext, useEffect, useState } from "react";
import {
  useConstantCountries,
  useConstantLeagues,
  useConstantUsers,
} from "../api/queries";

const defaultState = {
  users: [],
  countries: [],
  leagues: [],
};

interface ConstantsContextInterface {
  users: User[] | undefined;
  countries: string[] | undefined;
  leagues: League[] | undefined;
}

export const ConstantsContext =
  createContext<ConstantsContextInterface>(defaultState);

export const ConstantsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [countries, setCountries] = useState<string[] | undefined>(undefined);
  const [leagues, setleagues] = useState<League[] | undefined>(undefined);

  const allUsers = useConstantUsers().data;
  const allCountries = useConstantCountries().data;
  const allLeagues = useConstantLeagues().data;

  useEffect(() => {
    if (allUsers) {
      setUsers(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (allCountries) {
      setCountries(allCountries);
    }
  }, [allCountries]);

  useEffect(() => {
    setleagues(allLeagues);
  }, [allLeagues]);

  const value = { users, countries, leagues };

  return (
    <ConstantsContext.Provider value={value}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstantsContext = () => useContext(ConstantsContext);
