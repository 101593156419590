import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import AnalyticsDataGrid from "./AnalyticsDataGrid";
import { Box } from "@mui/material";
import ScoutingDataGrid from "./ScoutingDataGrid";
import useLocalStorage from "react-use-localstorage";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

const VIEW_MODE_ANALYTICS = "analytics";
const VIEW_MODE_SCOUTING = "scouting";

export default function Players() {
  // CONTEXT
  const { height } = useWindowSizeContext();

  // VARIABLES
  const adjHeight = height - APPBAR_HEIGHT_AS_NUM;

  // STATES
  const [viewMode, setViewMode] = useLocalStorage(
    "players_view_mode",
    VIEW_MODE_SCOUTING
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: adjHeight,
        maxHeight: adjHeight,
      }}
    >
      {viewMode === VIEW_MODE_ANALYTICS && (
        <AnalyticsDataGrid viewMode={viewMode} setViewMode={setViewMode} />
      )}
      {viewMode === VIEW_MODE_SCOUTING && (
        <ScoutingDataGrid viewMode={viewMode} setViewMode={setViewMode} />
      )}
    </Box>
  );
}
