import {
  Avatar,
  Box,
  LinearProgress,
  ListItemText,
  MenuItem,
  MenuList,
  PopoverOrigin,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DepthChartPlayer, Player } from "../../../api/types";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
} from "react-draggable";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { PLAYER_SEARCH } from "../../../api/keys";
import PitchPlayerDraggableList from "./PitchPlayerDraggableList";
import { percentageOffsetToPixel } from "../../../utils/pixels";
import rfdc from "rfdc";
import { useCreateDepthChartPlayerMutation } from "../../../api/mutations";
import { useDebounce } from "use-debounce";
import { useDepthChartContext } from "../../../contexts/DepthChartContext";
import useLocalStorage from "react-use-localstorage";
import { usePlayerSearch } from "../../../api/queries";
import { useQueryClient } from "react-query";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

const PLAYER_IN_SEARCH_HEIGHT = 46;
const PLAYERS_TO_DISPLAY_IN_SEARCH = 3;
const PLAYER_SEARCH_LIST_MAX_HEIGHT =
  PLAYER_IN_SEARCH_HEIGHT * PLAYERS_TO_DISPLAY_IN_SEARCH;

const deepCopy = rfdc();

interface PlayerProps {
  position: string;
  topOffset: string;
  leftOffset: string;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  pitchRef: any;
  depthChartId: number;
}

export default function PitchPlayer(props: PlayerProps) {
  // STORAGE
  const [savedCoordinates, setSavedCoordinates] = useLocalStorage(
    `depth-charts-${props.depthChartId}-position-${props.position}-coordinates`,
    undefined
  );
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { depthChartInContext, setDepthChartInContext } =
    useDepthChartContext();
  const queryClient = useQueryClient();

  // STATE
  const [playerSearchAnchorEl, setPlayerSearchAnchorEl] =
    useState<null | HTMLElement>(null);
  const [hidePlayerList, setHidePlayerList] = useState(false);
  const [playerName, setPlayerName] = useState<string>();
  const [debouncedPlayerName] = useDebounce(playerName, 300);
  const [players, setPlayers] = useState<DepthChartPlayer[]>();
  const [coordinates, setCoordinates] = useState<ControlPosition>();
  const [searchForPlayer, setSearchForPlayer] = useState(false);

  const playerAvatarRadius = isScreenSmall ? 16 : 24;

  // MUTATIONS
  const createDepthChartPlayer = useCreateDepthChartPlayerMutation();

  // QUERIES
  const searchPlayerQuery = usePlayerSearch({
    query: debouncedPlayerName || "",
    by: "name",
  });

  // REFS
  const ref = useRef(null);
  const playerMenuRef = useRef<HTMLDivElement>(null);

  // FUNCTIONS
  const onPlayerSearchResultClick = (player: Player) => {
    // add player to position depth chart
    const payload = {
      depth_chart_id: depthChartInContext?.id,
      player_id: player.id,
      position: props.position,
    };

    createDepthChartPlayer.mutate(payload as DepthChartPlayer);
    setPlayerName("");

    // Per Kevin: leave in edit mode after player is added
    // props.setEditMode(!props.editMode);
  };

  // used to maintain the visual order of the ordered players while they update in the backend
  const updateDepthChartPlayers = (
    updatedDepthChartPlayers: DepthChartPlayer[] | undefined
  ) => {
    const updatedDepthChart = deepCopy(depthChartInContext);

    if (updatedDepthChart) {
      updatedDepthChartPlayers?.forEach((udcp) => {
        const playerToUpdate = updatedDepthChart?.depth_chart_players.find(
          (dcp) => dcp.id === udcp.id
        );
        if (playerToUpdate) {
          playerToUpdate.rank = udcp.rank;
        }
      });

      setDepthChartInContext(updatedDepthChart);
    }
  };

  // EFFECTS
  useEffect(() => {
    setPlayerSearchAnchorEl(ref.current);
  }, [ref]);

  useEffect(() => {
    const handleClickOutsidePlayerMenu = (event: MouseEvent | TouchEvent) => {
      if (
        playerMenuRef.current &&
        !playerMenuRef?.current.contains(event.target as Node)
      ) {
        setPlayerName("");
        setSearchForPlayer(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutsidePlayerMenu, false);
    document.addEventListener("touchend", handleClickOutsidePlayerMenu, false);

    return () => {
      document.removeEventListener(
        "mouseup",
        handleClickOutsidePlayerMenu,
        false
      );
      document.removeEventListener(
        "touchend",
        handleClickOutsidePlayerMenu,
        false
      );
    };
  }, [playerMenuRef]);

  useEffect(() => {
    setSearchForPlayer(false);
  }, [props.editMode]);

  useEffect(() => {
    setPlayers(
      depthChartInContext &&
        depthChartInContext?.depth_chart_players &&
        depthChartInContext?.depth_chart_players.filter(
          (p: DepthChartPlayer) => {
            return p.position === props.position;
          }
        )
    );
  }, [depthChartInContext, props.position]);

  useEffect(() => {
    if (savedCoordinates && !isScreenSmall) {
      setCoordinates(JSON.parse(savedCoordinates) as ControlPosition);
    } else {
      if (props.pitchRef && props.pitchRef.current) {
        const { width, height } =
          props.pitchRef.current.getBoundingClientRect();
        setCoordinates({
          x: percentageOffsetToPixel(width, props.leftOffset),
          y: percentageOffsetToPixel(height, props.topOffset),
        } as ControlPosition);
      }
    }
  }, [
    isScreenSmall,
    savedCoordinates,
    props.leftOffset,
    props.topOffset,
    props.pitchRef,
  ]);

  // VARIABLES
  const transformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center",
  };

  if (isScreenSmall) {
    if (props.position === "7" || props.position === "2") {
      transformOrigin.horizontal = "right";
    } else if (props.position === "11" || props.position === "3") {
      transformOrigin.horizontal = "left";
    }
  }

  //   console.debug(
  //     `searchForPlayer: ${searchForPlayer} | editMode: ${props.editMode}`
  //   );

  //   console.debug(
  //     `position: ${props.position} | (${coordinates?.x}, ${coordinates?.y})`
  //   );

  return (
    <>
      <Draggable
        bounds="parent"
        nodeRef={ref}
        position={coordinates}
        onDrag={(e: DraggableEvent, data: DraggableData) => {
          setHidePlayerList(true);
          setCoordinates({
            x: data.x,
            y: data.y,
          } as ControlPosition);
        }}
        onStop={(e: DraggableEvent, data: DraggableData) => {
          setHidePlayerList(false);
          if (!isScreenSmall) {
            setSavedCoordinates(
              JSON.stringify({
                x: data.x,
                y: data.y,
              } as ControlPosition)
            );
          }
        }}
      >
        <Tooltip
          key={`player-position-${props.position}-tooltip`}
          placement="top"
          title="Click and hold to drag"
        >
          <Avatar
            ref={ref}
            aria-describedby={`player-${props.position}-popover`}
            sx={{
              position: "absolute",
              cursor: "grab",
              backgroundColor: theme.palette.primary.main,
              width: playerAvatarRadius,
              height: playerAvatarRadius,
            }}
          >
            <Typography variant="button">{props.position}</Typography>
          </Avatar>
        </Tooltip>
      </Draggable>

      {/* Hide menu when avatar is actively being dragged */}

      {!hidePlayerList && (
        <>
          <Popper
            id={`player-${props.position}-popover`}
            open={true}
            anchorEl={playerSearchAnchorEl}
            placement="bottom"
            modifiers={[
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
            sx={{
              maxHeight: PLAYER_SEARCH_LIST_MAX_HEIGHT,
            }}
          >
            {depthChartInContext && (
              <Box sx={{ width: 200 }}>
                {players && players.length > 0 && (
                  <PitchPlayerDraggableList
                    depthChartId={depthChartInContext.id}
                    position={props.position}
                    updateDepthChartPlayers={updateDepthChartPlayers}
                    players={players}
                    editMode={props.editMode}
                  />
                )}
                {props.editMode && !searchForPlayer && (
                  <Box
                    onClick={() => {
                      setSearchForPlayer(true);
                    }}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <AddCircleOutlineIcon
                      sx={{
                        marginTop: 0.5,
                        height: 20,
                        width: 20,
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                )}

                {props.editMode && searchForPlayer && (
                  <Box ref={playerMenuRef}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 0.5,
                      }}
                    >
                      <TextField
                        placeholder="Add Player"
                        inputRef={(inputRef) => inputRef && inputRef.focus()}
                        value={playerName}
                        type="search"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          queryClient.invalidateQueries(PLAYER_SEARCH);
                          setPlayerName(event.target.value);
                        }}
                        sx={{
                          backgroundColor: "white",
                          width: 200,
                          borderRadius: 1,
                        }}
                        inputProps={{
                          sx: {
                            fontSize: 14,
                            padding: 1,
                            borderRadius: 1,
                            borderColor: theme.palette.primary.main,
                            borderStyle: "solid",
                            borderWidth: 1,
                          },
                        }}
                      />
                    </Box>

                    {(searchPlayerQuery.isFetching ||
                      searchPlayerQuery.isLoading) && (
                      <LinearProgress
                        color="secondary"
                        sx={{ borderRadius: 1 }}
                      />
                    )}

                    <MenuList
                      disablePadding
                      dense
                      sx={{
                        backgroudColor: "white !important",
                        borderRadius: 1,
                        maxHeight: PLAYER_SEARCH_LIST_MAX_HEIGHT,
                        overflowY:
                          searchPlayerQuery &&
                          searchPlayerQuery.data &&
                          searchPlayerQuery?.data?.length >
                            PLAYERS_TO_DISPLAY_IN_SEARCH
                            ? "scroll"
                            : "hidden",
                      }}
                    >
                      {searchPlayerQuery.data?.map((p: Player, i: number) => {
                        return (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              onPlayerSearchResultClick(p);
                            }}
                            sx={{
                              backgroundColor: "white",
                              padding: 0.5,
                              "&:hover": {
                                bgcolor: theme.palette.grey[200],
                              },
                            }}
                          >
                            <Box>
                              <Avatar
                                src={p.image}
                                sx={{ height: 32, width: 32 }}
                              />
                            </Box>

                            <ListItemText
                              sx={{ paddingLeft: 1 }}
                              primary={
                                <Stack
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography variant="david" noWrap={true}>
                                    {p.name}
                                  </Typography>
                                </Stack>
                              }
                              secondary={
                                <Stack
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography noWrap variant="david">
                                    {p.team}
                                  </Typography>
                                </Stack>
                              }
                            />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Box>
                )}
              </Box>
            )}
          </Popper>
        </>
      )}
    </>
  );
}
