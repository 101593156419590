import { Box } from "@mui/material";
import LeagueTable from "./LeagueTable";
import { useLeagueTable } from "../../api/queries";

const OperationsPage = () => {
  const leagueTableData = useLeagueTable().data;

  return (
    <Box p={4}>
      {leagueTableData && <LeagueTable tableEntries={leagueTableData} />}
    </Box>
  );
};

export default OperationsPage;
