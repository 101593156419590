import {
  Container,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import CloseButton from "../buttons/CloseButton";
import LoadingSpinnerOverlay from "../LoadingSpinnerOverlay";
import PlayerNotes from "../PlayerNotes";
import { SEARCH_PARAMS_NOTE_ID } from "../../constants";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { usePlayerNotes } from "../../api/queries";
import { useSearchParams } from "react-router-dom";

export default function NotesDialog() {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { playerInContext } = usePlayerContext();
  const [open, setOpen] = useState<boolean>(false);
  const playerNotesQuery = usePlayerNotes(playerInContext?.id);
  const notes = playerNotesQuery.data;
  const fullScreen = true;

  //   console.debug(!!searchParams.get(SEARCH_PARAMS_NOTE_ID))
  //   console.debug(!!(playerNotes && playerNotes?.length > 0))
  //   console.debug(open)

  useEffect(() => {
    setOpen(!!searchParams.get(SEARCH_PARAMS_NOTE_ID));
  }, [searchParams]);

  const handleClose = () => {
    searchParams.delete(SEARCH_PARAMS_NOTE_ID);
    setSearchParams(searchParams);
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 5,
          maxWidth: "100%",
        },
      }}
      fullScreen={fullScreen}
      sx={{
        // https://stackoverflow.com/questions/6365448/google-chrome-not-respecting-z-index
        position: "absolute",
        zIndex: theme.zIndex.modal + 1,
      }}
    >
      <CloseButton
        position="absolute"
        top={10}
        left={10}
        onClick={() => handleClose()}
      />

      <DialogContent sx={{ padding: fullScreen ? 1 : 2 }}>
        <Container
          sx={{
            marginTop: 2,
            height: "100%",
            width: "100%",
            minHeight: 500,
          }}
        >
          <Typography
            alignContent="center"
            textAlign="center"
            justifyContent="center"
            variant="h6"
            ml={4}
            mr={4}
            display="flex"
            flexGrow={1}
          >
            Notes on {playerInContext?.name}
          </Typography>

          {(!playerInContext ||
            playerNotesQuery.isFetching ||
            playerNotesQuery.isLoading) && <LoadingSpinnerOverlay />}

          {playerInContext && playerNotesQuery.isFetched && (
            <PlayerNotes dialog={true} notes={notes} />
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
}
