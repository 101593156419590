import * as d3 from "d3";

import { Box, alpha } from "@mui/system";
import {
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";

import { isNumber } from "util";
import { useListContext } from "../../contexts/ListContext";

// const normalizeToOpacity = (value: number, min: number, max: number) => {
//   // Ensure the value is within the range
//   value = Math.max(min, value);
//   value = Math.min(max, value);

//   // Normalize to 0-1 range
//   return (value - min) / (max - min);
// };

export const CellWithColor = (props: { params: GridRenderCellParams }) => {
  //   const theme = useTheme();
  const { listInContext } = useListContext();

  let color = "#FFFFFF";
  let value = props.params.value;
  let max = 0;
  let min = 0;

  // Needed if doing two colors + opacity
  // const isPositive = value > 0;

  if (listInContext && props.params.value && isNumber(props.params.value)) {
    value = parseFloat(props.params.value.toFixed(3));

    props.params.api.getRowModels().forEach((row: GridValidRowModel) => {
      if (row[props.params.field] > max) {
        max = row[props.params.field] as number;
      }
      if (row[props.params.field] < min) {
        min = row[props.params.field] as number;
      }
    });

    // const opacity = normalizeToOpacity(value, min, max);
    const colorGenerator = d3.scaleLinear(
      [min, (min + max) / 2, max],
      ["red", "yellow", "green"]
    );

    color = alpha(colorGenerator(value), 0.6);
  }

  return (
    <Box
      sx={{
        backgroundColor: color,
        width: "100%",
        height: "100%",
      }}
    >
      {value}
    </Box>
  );
};
