import { createContext, useContext, useState } from "react";

import CustomAlert from "../components/CustomAlert";

interface AlertContextInterface {
  setAlertOptions: (options: AlertOptions) => void;
}

export const SEVERITY_ERROR = "error";
export const SEVERITY_INFO = "info";
export const SEVERITY_SUCCESS = "success";
export const SEVERITY_WARNING = "warning";

export const NO_DATA_FOUND_ALERT_MESSAGE = "no data found";

export interface AlertOptions {
  link?: string;
  message: string;
  messageLink?: string;
  severity: string;
  title?: string;
}

export const defaultOptions = {
  link: "/",
  message: "",
  messageLink: "",
  severity: SEVERITY_INFO,
  title: "",
} as AlertOptions;

const AlertContext = createContext<AlertContextInterface>({
  setAlertOptions: () => {},
});

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alertOptions, _setAlertOptions] =
    useState<AlertOptions>(defaultOptions);

  const setAlertOptions = (options: AlertOptions): void => {
    _setAlertOptions(options);
  };

  return (
    <AlertContext.Provider value={{ setAlertOptions }}>
      {alertOptions.message && (
        <CustomAlert
          link={alertOptions.link}
          message={alertOptions.message}
          messageLink={alertOptions.messageLink}
          severity={alertOptions.severity}
          title={
            alertOptions?.title
              ? alertOptions.title
              : alertOptions.severity.toUpperCase()
          }
          setAlertOptions={setAlertOptions}
        />
      )}

      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);
