import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, styled } from "@mui/material"

import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import packageJson from "../../package.json";
import { useCacheBuster } from "react-cache-buster";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: "6px",
    }
}));

const NavFooter = () => {
    const { checkCacheStatus } = useCacheBuster();

    return (
        <List dense>
            <Divider />
            <StyledListItemButton onClick={checkCacheStatus}>
                <Tooltip title="Check for new version">
                    <ListItemIcon>
                        <SystemUpdateIcon fontSize="small" />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText>Version: {packageJson.version}</ListItemText>
            </StyledListItemButton>
        </List>
    )
}

export default NavFooter;
