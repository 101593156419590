import { CHICAGO_FIRE, MLS, SSL } from "../constants";
// Taken from https://techinscribed.com/building-react-app-using-material-ui-with-support-for-multiple-switchable-themes/
import { createContext, useContext, useEffect, useState } from "react";
import { useIsFetching, useQueryClient } from "react-query";

import { ReactComponent as CffcLogo } from "../assets/logos/cffc.svg";
import { ReactComponent as LuganoLogo } from "../assets/logos/lugano.svg";

const LOCAL_STORAGE_KEY = "club";

const localStorageClub = localStorage.getItem(LOCAL_STORAGE_KEY);

if (localStorageClub == null) {
  localStorage.setItem(LOCAL_STORAGE_KEY, CHICAGO_FIRE);
}

const currClub = localStorageClub != null ? localStorageClub : CHICAGO_FIRE;

const defaultState = {
  club: currClub,
  league: MLS,
  logo: <CffcLogo />,
  setClub: (club: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, club);
  },
};

interface iClubContext {
  club: string;
  league: string;
  logo: JSX.Element;
  setClub: (clubName: string) => void;
}

export const ClubContext = createContext<iClubContext>(defaultState);

export const ClubProvider = ({children}: {children: React.ReactNode}) => {
  const isFetching = useIsFetching();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [club, _setClub] = useState(currClub);

  const setClub = (club: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEY, club);
    _setClub(club);
  };

  const dims = "2rem";

  const spinningCffcLogo = (
    <CffcLogo id="logo" style={{ width: dims, height: dims }} />
  );
  const spinningLuganoLogo = (
    <LuganoLogo id="logo" style={{ width: dims, height: dims }} />
  );

  let logo;
  let league;
  const queryCache = queryClient.getQueryCache();

  useEffect(() => {
    // const activeQueries = queryCache.findAll({ fetching: true, active: true });
    // const activeKeys = activeQueries
    //   .flatMap((key) => key.queryKey)
    //   .filter((key) => typeof key === "string");

    // console.debug(`isFetching: ${isFetching} | activeKeys: ${activeKeys.length}`);

    setLoading(isFetching > 0);
  }, [queryCache, queryClient, isFetching]);

  // console.debug(loading);

  if (club === CHICAGO_FIRE) {
    logo = loading ? spinningCffcLogo : <CffcLogo style={{ width: dims, height: dims }} />;
    league = MLS;
  } else {
    logo = loading ? spinningLuganoLogo : <LuganoLogo style={{ width: dims, height: dims }} />;
    league = SSL;
  }

  const value = { 
    club: club,
    league: league,
    logo: logo,
    setClub: setClub
  };

  return <ClubContext.Provider value={value}>{children}</ClubContext.Provider>;
};

export const useClubContext = () => useContext(ClubContext);
