import { Box, DialogContentText } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export interface UpdateDialogProps {
  open: boolean;
  rows: number;
  title: string;
  verbage: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  yesAction: (value: string | number) => void;
  content?: string;
  customComponent?: JSX.Element;
}

export default function UpdateDialog(props: UpdateDialogProps) {
  const [value, setValue] = useState(props.content || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // https://stackoverflow.com/questions/54865764/react-usestate-does-not-reload-state-from-props
  useEffect(() => {
    if (props.content) {
      setValue(props?.content);
    } else {
      setValue("");
    }
  }, [props.content]);

  return (
    <Box>
      <Dialog
        open={props.open}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 5 }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CloseButton position="absolute" onClick={() => props.setOpen(false)} />

        <DialogTitle justifyContent="center" alignItems="center" display="flex">
          {props.title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            justifyContent="center"
            alignItems="center"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: 500,
              maxWidth: "100%",
            }}
          >
            {props.customComponent && props.customComponent}

            {!props.customComponent && (
              <TextField
                margin="dense"
                id="value"
                label="Content"
                type="text"
                fullWidth
                variant="standard"
                multiline
                rows={props.rows}
                value={value}
                onChange={handleChange}
                sx={{ whiteSpace: "pre-line" }}
              />
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              props.customComponent
                ? props.yesAction(value)
                : props.yesAction(value);
            }}
          >
            {props.verbage}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
