import { AlertOptions, defaultOptions } from "../contexts/AlertContext";
import { IconButton, Link, Portal } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { forwardRef, useEffect, useState } from "react";

import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

export interface CustomAlertProps {
  link?: string;
  message: string;
  messageLink?: string;
  severity: string;
  title?: string;
  setAlertOptions?: (options: AlertOptions) => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomAlert(props: CustomAlertProps) {
  const [open, setOpen] = useState(false);

  // reset open if options.open changes
  useEffect(() => {
    // console.debug(props.message);

    if (props.message !== defaultOptions.message) {
      setOpen(true);
    }

    // Wait three seconds then close the alert
    setTimeout(() => {
      setOpen(false);
      if (props.setAlertOptions) {
        props.setAlertOptions(defaultOptions);
      }
    }, 4000);
  }, [props]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpen(false);
  };

  // console.debug(`snackbar open: ${open}`)
  const title = props.title ? props.title?.toUpperCase() : props.message;

  return (
    <Portal>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={props.severity as AlertColor}
          action={[
            <IconButton
              key={`icon-button-close-${props.messageLink}`}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>,
          ]}
        >
          <AlertTitle>{title}</AlertTitle>
          {props.message.toLowerCase()}
          {props?.link && props?.messageLink && (
            <Link href={props.link} underline="always">
              {props.messageLink}
            </Link>
          )}
        </Alert>
      </Snackbar>
    </Portal>
  );
}
