import { alpha, useTheme } from "@mui/material/styles";

import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";

const EnhancedTableRow = styled(TableRow)(() => {
    const theme = useTheme();
    return {
        // hide last border
        "&:last-child th": {
            border: 0,
        },
        "&.Mui-selected, &.MuiTableRow-hover:hover": {
            background: alpha(theme.palette.info.main, 0.25),
        },
    };
});

export default EnhancedTableRow;
