import { Folder, PlayerList } from "../api/types";

import ListLabel from "./ListLabel";
import { NavItemIcon } from "./NavItemIcon";
import { SCOUTING_PLAYERS_ROUTE } from "../routes";
import { TreeItem } from "./TreeItem";
import { useListContext } from "../contexts/ListContext";
import { useNavigate } from "react-router-dom";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

interface NavListProps {
  folder: Folder;
  list: PlayerList;
  folderIndex: number;
  index: number;
}

export default function NavList(props: NavListProps) {
  const navigate = useNavigate();
  const { listInContext, setListInContext } = useListContext();
  const { email } = useOktaAuthContext();

  const selectedList = props.list.id === listInContext?.id;

  return (
    <TreeItem
      draggable={props.list.owner === email}
      itemId={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.folder.name}-tree-item-label`}
      label={<ListLabel folder={props.folder} list={props.list} />}
      slots={{
        icon: () => (
          <NavItemIcon
            name={props.list.name}
            obj={props.list}
            selected={selectedList}
            setObjInContext={setListInContext}
          />
        ),
      }}
      onClick={() => {
        if (props.list === listInContext) {
          setListInContext(undefined);
          navigate(SCOUTING_PLAYERS_ROUTE);
        } else {
          setListInContext(props.list);
          navigate(`${SCOUTING_PLAYERS_ROUTE}?list_id=${props.list.id}`);
        }
      }}
    />
  );
}
