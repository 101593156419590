import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  useDeleteFolderMutation,
  useUpdateFolderMutation,
} from "../../api/mutations";

import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import { Folder } from "../../api/types";
import FolderDeleteTwoToneIcon from "@mui/icons-material/FolderDeleteTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UpdateDialog from "../../components/dialogs/UpdateDialog";
import { useOktaAuthContext } from "../../contexts/OktaAuthContext";
import { useState } from "react";

export interface FolderMenuProps {
  index: number;
  folder: Folder;
}

export function FolderActionMenu(props: FolderMenuProps) {
  // CONTEXT
  const { email } = useOktaAuthContext();

  // STATE
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [updateFolderModalOpen, setUpdateFolderModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  // MUTATIONS
  const deleteFolder = useDeleteFolderMutation();
  const updateFolder = useUpdateFolderMutation();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box key={`${props.folder.id}-${props.index}-folder-nav-menu`}>
      <ConfirmationDialog
        key={`${props.folder.id}-${props.index}-delete-folder-confirmation-dialog`}
        open={deleteFolderModalOpen}
        title={`Are you sure you want to delete "${props.folder.name}"?`}
        content={'Lists will be moved to "DEFAULT" folder'}
        yesAction={() => {
          deleteFolder.mutate(props.folder);
          setDeleteFolderModalOpen(false);
        }}
        noAction={() => {
          setDeleteFolderModalOpen(false);
        }}
      />

      <UpdateDialog
        key={`${props.folder.id}-${props.index}-update-folder-dialog`}
        open={updateFolderModalOpen}
        content={props.folder.name}
        rows={1}
        title={`Rename Folder: ${props.folder.name}`}
        verbage={"Rename"}
        setOpen={setUpdateFolderModalOpen}
        yesAction={(newValue) => {
          props.folder.name = newValue as string;
          updateFolder.mutate(props.folder);
          setUpdateFolderModalOpen(false);
        }}
      />

      <Menu
        key={`${props.folder.id}-${props.index}-actions-menu`}
        aria-labelledby="folder-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List key={`${props.folder.id}-${props.index}-actions-menu-list`}>
          {props.folder.owner === email && (
            <MenuItem
              key={`${props.folder.id}-${props.index}-actions-menu-item-delete-folder`}
              onClick={(event) => {
                setDeleteFolderModalOpen(true);
                event.stopPropagation();
              }}
            >
              <ListItemIcon
                key={`${props.folder.id}-${props.index}-actions-menu-delete-folder-list-item-icon`}
                sx={{ mr: 1 }}
              >
                <FolderDeleteTwoToneIcon
                  key={`${props.folder.id}-${props.index}-actions-menu-delete-folder-icon`}
                  fontSize={"small"}
                  color="primary"
                />
              </ListItemIcon>

              <Typography
                key={`${props.folder.id}-${props.index}-actions-menu-delete-folder-typography`}
                variant="caption"
              >
                DELETE
              </Typography>
            </MenuItem>
          )}

          {props.folder.owner === email && (
            <MenuItem
              key={`${props.folder.id}-${props.index}-actions-menu-item-update-name`}
              onClick={(event) => {
                setUpdateFolderModalOpen(true);
                event.stopPropagation();
              }}
            >
              <ListItemIcon
                key={`${props.folder.id}-${props.index}-actions-menu-update-folder-name-icon`}
                sx={{ mr: 1 }}
              >
                <CreateTwoToneIcon
                  key={`${props.folder.id}-${props.index}-actions-menu-update-folder-name-icon`}
                  fontSize={"small"}
                  color="primary"
                />
              </ListItemIcon>

              <Typography
                key={`${props.folder.id}-${props.index}-actions-menu-update-folder-name-typography`}
                variant="caption"
              >
                RENAME
              </Typography>
            </MenuItem>
          )}
        </List>
      </Menu>

      <IconButton
        key={`${props.folder.id}-${props.index}-folder-actions-menu-button`}
        edge="end"
        aria-label="expand"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenuClick}
        sx={{
          p: 0,
          marginRight: 0.15,
        }}
      >
        <MoreVertIcon
          key={`${props.folder.id}-${props.index}-folder-actions-menu-vert-icon`}
          fontSize="small"
          color="primary"
        />
      </IconButton>
    </Box>
  );
}
