import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  ToolbarPropsOverrides,
} from "@mui/x-data-grid-premium";

import { ANALYTICS_ROLE } from "../constants";
import { Dispatch } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { PlayerTableViewSwitch } from "./PlayerTableViewSwitch";
import { useThemeContext } from "../contexts/CustomThemeContext";
import { useUserContext } from "../contexts/UserContext";

// https://mui.com/x/react-data-grid/components/#custom-slot-props-with-typescript
// augment the props for the toolbar slot
declare module "@mui/x-data-grid-premium" {
  interface ToolbarPropsOverrides {
    viewMode: string;
    createPlayer?: () => void;
    setViewMode: Dispatch<string>;
  }
}

export function CustomGridToolbar(props: ToolbarPropsOverrides) {
  const { primaryRole } = useUserContext();
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: "1px solid",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {primaryRole === ANALYTICS_ROLE && (
        <>
          <Box
            sx={{
              flex: "1 1 0", // flex-basis: 0 so that items don't change in width depending on if "add player" button is rendered or not in search item
              width: "100%",
            }}
          >
            <PlayerTableViewSwitch
              viewMode={props.viewMode}
              setViewMode={(newViewMode: string) => {
                props.setViewMode(newViewMode);
              }}
            />
          </Box>

          <Divider orientation="vertical" flexItem color="primary" />
        </>
      )}

      <Box
        sx={{
          flex: "1 1 0",
          display: "flex",
          justifyContent: isScreenSmall ? "space-around" : "center",
          width: "100%",
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </Box>

      <Divider orientation="vertical" flexItem color="primary" />

      <Box
        sx={{
          flex: "1 1 0",
          display: "flex",
          flexGrow: 1,
          justifyContent: isScreenSmall ? "space-around" : "center",
        }}
      >
        <GridToolbarQuickFilter
          sx={{
            p: 0,
            "& .MuiInput-root": {
              flexGrow: 1,
              width: isScreenSmall ? "95vw" : "30vw",
            },
            "& .MuiInput-root::before": {
              border: 0,
            },
            "& .MuiInput-root::after": {
              border: 0,
            },
            "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
              border: 0,
            },
          }}
          debounceMs={200}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={"create player"}
                  onClick={props.createPlayer}
                >
                  <PersonAddIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
}
