import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import styled from "@emotion/styled";

export type TableCellsFunc<T> = (p: T) => JSX.Element;

export interface EnhancedHeadCell<T> {
    id: keyof T;
    label: string;
    numeric: boolean;
    displayOnSmallScreen?: boolean,
    displayOnMediumScreen?: boolean,
    elongated?: string;
    explanation?: string;
    color?: string;
    sortKey?: string;
    colSpan?: number;
}

const EnhancedTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-root": {
        "&.MuiTableCell-head": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            noWrap: true,
        },
        "&.MuiTableCell-body": {
            fontSize: 14,
            fontWeight: 400,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            noWrap: true,
        },
    },
    [`&.${tableCellClasses.head}`]: {
        background: "#fff",
        color: "black",
        fontSize: 16,
        fontWeight: 600,
        noWrap: true,
        position: "sticky",
        top: 0,
    },
}));

export default EnhancedTableCell;
