import { useQueryClient } from "react-query";
import { useWebSocketContext } from "../contexts/WebSocketContext";

// CREATE
export const CREATE_DEPTH_CHART = "create_depth_chart";
export const CREATE_DEPTH_CHART_PLAYER = "create_depth_chart_player";
export const CREATE_FOLDER = "create_folder";
export const CREATE_LIST = "create_list";
export const CREATE_MATCH_REPORT = "create_match_report";
export const CREATE_NOTE = "create_note";
export const CREATE_PLAYER = "create_player";
export const CREATE_PLAYER_ATTRIBUTES = "create_player_attributes";
export const CREATE_SCOUTED_PLAYER = "create_scouted_player";
export const CREATE_SCOUTING_REPORT = "create_scouting_report";

// DELETE
export const DELETE_DEPTH_CHART_PLAYER = "delete_depth_chart_player";
export const DELETE_FOLDER = "delete_folder";
export const DELETE_LIST = "delete_list";
export const DELETE_SCOUTING_REPORT = "delete_scouting_report";

// READ
export const GET_AUTH = "get_auth";
export const GET_CONSTANT_COUNTRIES = "get_constant_countries";
export const GET_CONSTANT_LEAGUES = "get_constant_leagues";
export const GET_DEPTH_CHART = "get_depth_chart";
export const GET_DEPTH_CHARTS = "get_depth_charts";
export const GET_DEPTH_CHART_PLAYERS = "get_depth_chart_players";
export const GET_FOLDERS = "get_folders";
export const GET_LIST = "get_list";
export const GET_LISTS = "get_lists";
export const GET_MATCH_REPORTS = "get_match_reports";
export const GET_MATCH_REPORT_QUALITIES = "get_match_report_qualities";
export const GET_NOTES = "get_notes";
export const GET_NOTIFICATIONS = "get_notifications";
export const GET_OPERATIONS_DATA = "get_operations_data";
export const GET_PLAYER = "get_player";
export const GET_PLAYER_ATTRIBUTES = "get_player_attributes";
export const GET_PLAYER_DATABASE_META = "get_player_database_meta";
export const GET_PLAYERS = "get_player_database";
export const GET_PLAYER_KPIS = "get_player_kpis";
export const GET_PLAYER_MATCH_REPORTS = "get_player_match_reports";
export const GET_PLAYER_NOTES = "get_player_notes";
export const GET_PLAYER_SCOUTING_REPORTS = "get_player_scouting_reports";
export const GET_PLAYER_SEASON_STATS = "get_player_season_stats";
export const GET_PLAYER_WEEKLY_STATS = "get_player_weekly_stats";
export const GET_SCOUTED_PLAYERS = "get_scouted_players";
export const GET_SCOUTING_REPORTS = "get_scouting_reports";
export const GET_SHARES = "get_shares";
export const GET_TABLE_METRICS = "get_table_metrics";
export const GET_TEAM_STATS = "get_team_stats";
export const GET_USERS = "get_users";

// UPDATE
export const UPDATE_DEPTH_CHART = "update_depth_chart";
export const UPDATE_DEPTH_CHART_PLAYER = "update_depth_chart_player";
export const UPDATE_FOLDER = "update_folder";
export const UPDATE_LIST = "update_list";
export const UPDATE_LIST_PLAYERS = "update_list_players";
export const UPDATE_PLAYER = "update_player";
export const UPDATE_PLAYER_ATTRIBUTES = "update_player_attributes";
export const UPDATE_SCOUTED_PLAYER = "update_scouted_player";
export const UPDATE_SCOUTING_REPORT = "update_scouting_report";

// SEARCH
export const PLAYER_SEARCH = "player_search";

export function useInvalidate() {
  const queryClient = useQueryClient();
  const { sendMessage } = useWebSocketContext();

  const invalidate = (key: string) => {
    // Invalidate the associated react query and broadcast a message to all connected websockets through the API
    queryClient.invalidateQueries(key);
    sendMessage(key);
  };

  return invalidate;
}
