import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  alpha,
  useMediaQuery,
} from "@mui/material";
import {
  ChartData,
  Chart as ChartJS,
  Colors,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { STAT_INFO, TEAM_COLOR_MAP } from "../constants";
import { TableEntries, TableEntry } from "../api/types";
import { useEffect, useState } from "react";

import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";
import { Radar } from "react-chartjs-2";
import { useClubContext } from "../contexts/ClubContext";
import { useThemeContext } from "../contexts/CustomThemeContext";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Colors,
  Filler,
  Tooltip,
  Legend
);

const FONT_SIZE = 14;
const RADAR_LABELS = [
  "np_xg_pg",
  "np_xg_conceded_pg",
  "np_xgd_pg",
  "obv_pass_pg",
  "obv_dribble_carry_pg",
  "obv_pass_conceded_pg",
  "obv_dribble_carry_conceded_pg",
  "red_cards_pg",
  "yellow_cards_pg",
  "np_xg_for",
  "np_xg_against",
  "np_xg_diff",
  "ns_xg_for",
  "ns_xg_against",
  "ns_xg_diff",
];

export interface TeamMetricsRadarChartProps {
  leagueTableData: TableEntries | null | undefined;
  selectedRow: TableEntry | undefined;
}

const TeamMetricsRadarChart = (props: TeamMetricsRadarChartProps) => {
  const clubContext = useClubContext();
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState<ChartData<"radar", number[], unknown>>();
  const [dataType, setDataType] = useState<string>("rank");

  // console.debug(props?.selectedRow?.team_name);

  const chartOptions = {
    interaction: {
      intersect: false,
    },
    maintainAspectRatio: !isScreenSmall,
    elements: {
      point: {
        radius: 4,
        hoverRadius: 7,
      },
    },
    plugins: {
      colors: {
        enabled: false,
      },
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          font: {
            size: FONT_SIZE,
          },
        },
        // https://www.chartjs.org/docs/latest/configuration/legend.html#custom-on-click-actions
        onClick: (
          _event: any,
          legendItem: { datasetIndex: any; hidden: boolean; text: string },
          legend: { chart: any }
        ) => {
          // console.debug(legendItem);
          // console.debug(props.visibleLegendItems);

          const index = legendItem.datasetIndex;
          const ci = legend.chart;

          // console.debug(index);
          // console.debug(newVisibleLegendItems);

          if (index !== undefined) {
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          }
        },
      },
      title: {
        color: theme.palette.primary.main,
        display: true,
        font: {
          size: FONT_SIZE,
        },
      },
      tooltip: {
        titleFont: {
          size: FONT_SIZE,
        },
        bodyFont: {
          size: FONT_SIZE,
        },
        footerFont: {
          size: FONT_SIZE,
        },
      },
    },
    scales: {
      r: {
        reverse: dataType === "rank",
        pointLabels: {
          font: {
            size: FONT_SIZE,
          },
        },
      },
    },
  };

  useEffect(() => {
    if (props.leagueTableData) {
      const teams: TableEntry[] = props.leagueTableData[clubContext.league];
      const datasets = [];
      const labels = Object.keys(teams[0])
        .filter((key: string) => RADAR_LABELS.includes(key))
        .map((value: string) => {
          const key = `tss_${value}`;
          return STAT_INFO[key] ? STAT_INFO[key]?.label : value;
        });

      for (const team of teams) {
        const data = [];
        for (const label in team) {
          if (RADAR_LABELS.includes(label)) {
            const key = dataType === "rank" ? `${label}_rank` : label;
            data.push(team[key] as number);
          }
        }

        const datasetLabel = team.team_name;
        const color = TEAM_COLOR_MAP[datasetLabel]
          ? TEAM_COLOR_MAP[datasetLabel]
          : "#ccc";
        const borderColor = alpha(color, 0.8);
        const backgroundColor = alpha(color, 0.2);
        const hoverBackgroundColor = alpha(color, 0.6);

        datasets.push({
          hidden:
            clubContext.club === datasetLabel ||
            props?.selectedRow?.team_name === datasetLabel
              ? false
              : true,
          label: datasetLabel,
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 2,
          hoverBackgroundColor: hoverBackgroundColor,
        });
      }

      const data: ChartData<"radar", number[], unknown> = {
        labels: labels,
        datasets: datasets.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        ),
      };
      setData(data);
    }
  }, [
    clubContext.club,
    clubContext.league,
    dataType,
    props.leagueTableData,
    props?.selectedRow?.team_name,
  ]);

  if (data === undefined) {
    return <LoadingSpinnerOverlay />;
  }

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setDataType(value);
  };

  return (
    <Box height="100vh" width="100%">
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <ToggleButtonGroup
          orientation="vertical"
          aria-label="Basic button group"
          color="primary"
          value={dataType}
          onChange={handleChange}
          exclusive
        >
          <ToggleButton value="rank">Rank</ToggleButton>
          <ToggleButton value="value">Value</ToggleButton>
        </ToggleButtonGroup>

        <Radar data={data} options={chartOptions} />
      </Stack>
    </Box>
  );
};

export default TeamMetricsRadarChart;
