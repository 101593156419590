import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Pitch, { LIGHT_GREEN } from "./Pitch";

import { APPBAR_HEIGHT } from "../../../app_bar/Toolbar";
import { DepthChart as DepthChartType } from "../../../api/types";
import LoadingSpinnerOverlay from "../../../components/LoadingSpinnerOverlay";
import { SEARCH_PARAMS_DEPTH_CHART_ID } from "../../../constants";
import { useDepthChartContext } from "../../../contexts/DepthChartContext";
import { useDepthCharts } from "../../../api/queries";
import { useSearchParams } from "react-router-dom";

const DepthChart = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const depthCharts = useDepthCharts();
  const { depthChartInContext, depthChartQuery } = useDepthChartContext();

  // console.debug(depthChartInContext);
  if (depthChartInContext) {
    return (
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          height: `calc(100vh - ${APPBAR_HEIGHT})`,
          backgroundColor: LIGHT_GREEN,
        }}
      >
        <Pitch />
      </Box>
    );
  }

  if (depthChartQuery?.isLoading || depthChartQuery?.isFetching) {
    return <LoadingSpinnerOverlay />;
  } else {
    return (
      <Box display="flex" justifyContent="space-around" padding={2}>
        <List sx={{ width: "50%", maxWidth: 360, bgcolor: "background.paper" }}>
          <Typography variant="h5">Depth Charts</Typography>
          {depthCharts &&
            depthCharts?.data?.map((depthChart: DepthChartType) => (
              <ListItem
                key={depthChart.id}
                disableGutters
                onClick={() => {
                  searchParams.set(
                    SEARCH_PARAMS_DEPTH_CHART_ID,
                    depthChart.id.toString()
                  );
                  setSearchParams(searchParams);
                }}
              >
                <Link sx={{ cursor: "pointer" }}>
                  <ListItemText primary={depthChart.label} />
                </Link>
              </ListItem>
            ))}
        </List>
      </Box>
    );
  }
};

export default DepthChart;
