import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { League, PlayerCreate, PlayerUpdate } from "../../api/types";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  useCreatePlayerMutation,
  useUpdatePlayerMutation,
} from "../../api/mutations";

import CloseButton from "../buttons/CloseButton";
import { DateValidationError } from "@mui/x-date-pickers";
import DefaultDatePicker from "../DefaultDatePicker";
import { getUTCDate } from "../../utils/dates";
import { useConstantsContext } from "../../contexts/ConstantsContext";
import { usePlayerContext } from "../../contexts/PlayerContext";

interface MutatePlayerDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function MutatePlayerDialog(props: MutatePlayerDialogProps) {
  // CONTEXTS
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { countries, leagues } = useConstantsContext();
  const { playerInContext } = usePlayerContext();

  // STATES
  const [mlsEligible, setMLSEligible] = useState<boolean>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthdate, setBirthdate] = useState<Date | undefined>();
  const [dobError, setDOBError] = useState<DateValidationError | undefined>();
  const [team, setTeam] = useState<string>("");
  const [selectedLeague, setSelectedLeague] = useState<League | null>(null);
  const [league, setLeague] = useState<string>("");
  const [nationality, setNationality] = useState<string[]>([]);
  const [position, setPosition] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  // REFS
  const formRef = useRef();

  // MUTATIONS
  const createPlayer = useCreatePlayerMutation();
  const updatePlayer = useUpdatePlayerMutation();

  // FUNCTIONS
  const resetForm = () => {
    setMLSEligible(false);
    setFirstName("");
    setLastName("");
    setBirthdate(undefined);
    setTeam("");
    setSelectedLeague(null);
    setLeague("");
    setNationality([]);
    setPosition("");
  };

  const handleSubmit = () => {
    const payload = {
      mls_eligible: mlsEligible,
      first_name: firstName,
      last_name: lastName,
      birthdate: birthdate,
      team: team,
      league: league,
      nationality: nationality.toString(),
      position: position,
    };

    if (!playerInContext) {
      createPlayer.mutate(payload as PlayerCreate);
    } else {
      const obj = Object.fromEntries(
        Object.entries({ ...payload, id: playerInContext.id })
      );
      updatePlayer.mutate(obj as PlayerUpdate);
    }

    props.setOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      birthdate == null ||
      dobError != null
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, position, birthdate, dobError, team, nationality]);

  useEffect(() => {
    if (playerInContext) {
      setMLSEligible(playerInContext.mls_eligible);
      setFirstName(playerInContext.first_name);
      setLastName(playerInContext.last_name);
      setBirthdate(playerInContext.birthdate);
      setTeam(playerInContext.team);
      if (playerInContext.league) {
        setSelectedLeague({ league_name: playerInContext.league, country: "" });
      }
      setLeague(playerInContext.league);
      setNationality(playerInContext?.nationality?.split(","));
      setPosition(playerInContext.position);
    } else {
      resetForm();
    }
  }, [playerInContext, props.open]);

  useEffect(() => {
    setLeague(selectedLeague?.league_name || "");
  }, [selectedLeague]);

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown") {
            props.setOpen(false);
          }
        }}
        fullScreen={fullScreen}
        fullWidth
      >
        <CloseButton
          position="absolute"
          top={10}
          left={10}
          onClick={() => {
            props.setOpen(false);
            resetForm();
          }}
        />
        <DialogTitle sx={{ textAlign: "center" }}>{`${
          !playerInContext ? "CREATE" : "UPDATE"
        } PLAYER`}</DialogTitle>
        <ValidatorForm onSubmit={handleSubmit} ref={formRef.current}>
          <DialogContent>
            <Stack spacing={2}>
              <FormControlLabel
                label="MLS Eligible"
                control={
                  <Checkbox
                    checked={mlsEligible}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setMLSEligible(event.target.checked);
                    }}
                  />
                }
              />

              <TextValidator
                id="create-player-dialog-first-name-text"
                label="First Name"
                name="first_name"
                value={firstName}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFirstName(event.target.value)
                }
              />
              <TextValidator
                errorMessages={["this field is required"]}
                id="create-player-dialog-last-name-text"
                label="Last Name"
                name="player_name"
                validators={["required"]}
                value={lastName}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setLastName(event.target.value)
                }
              />
              <DefaultDatePicker
                key={"create-player-dialog-dob-picker"}
                label="Date of Birth"
                value={birthdate}
                onChange={(value: Date | null) => {
                  if (value) {
                    setBirthdate(getUTCDate(value));
                  }
                }}
                onError={(error) => setDOBError(error)}
              />
              <TextValidator
                id="create-player-dialog-position-text"
                label="Position"
                name="position"
                value={position}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPosition(event.target.value)
                }
              />
              <TextValidator
                id="create-player-dialog-team-text"
                label="Team"
                name="team"
                value={team}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTeam(event.target.value)
                }
              />
              <Autocomplete
                id="create-player-dialog-league"
                options={
                  leagues
                    ? [{ league_name: "College", country: "" }, ...leagues]
                    : []
                }
                getOptionLabel={(option) =>
                  `${option.league_name}${
                    option.country !== "" ? ` (${option.country})` : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField {...params} label="League" />
                )}
                value={selectedLeague}
                onChange={(event: any, l: League | null) =>
                  setSelectedLeague(l)
                }
              />
              <Autocomplete
                multiple
                id="create-player-dialog-nationality"
                options={countries || []}
                renderInput={(params) => (
                  <TextField {...params} label="Nationality" />
                )}
                value={nationality}
                onChange={(event: any, newValue: string[]) => {
                  setNationality(newValue);
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={disabled}>
              Submit
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
