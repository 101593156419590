import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseButton from "../buttons/CloseButton";
import { DepthChart } from "../../api/types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import TextField from "@mui/material/TextField";
import { useUpdateDepthChartMutation } from "../../api/mutations";

export type RenameDepthChartDialogProps = {
  open: boolean;
  depthChart: DepthChart;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectionModel?: GridRowSelectionModel;
};

export default function RenameDepthChartDialog(
  props: RenameDepthChartDialogProps
) {
  // STATE
  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(props.depthChart.label);
  }, [props.depthChart.label]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // MUTATIONS
  const updateDepthChart = useUpdateDepthChartMutation();

  return (
    <Dialog
      key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog`}
      open={props.open}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <CloseButton
        key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-close-btn`}
        top={10}
        left={10}
        position="absolute"
        onClick={() => props.setOpen(false)}
      />

      <DialogTitle
        key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-title`}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        Rename List
      </DialogTitle>

      <DialogContent
        key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-content`}
      >
        <DialogContentText
          key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-content-box`}
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-content-box-text`}
            margin="dense"
            id="value"
            label="List Name"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={1}
            value={value}
            onChange={handleChange}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions
        key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-actions`}
      >
        <Button
          key={`${props.depthChart.id}-${props.depthChart.label}-rename-dialog-actions-btn`}
          onClick={() => {
            const newDepthChart = props.depthChart;
            newDepthChart.label = value as string;
            updateDepthChart.mutate(newDepthChart);
            props.setOpen(false);
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
