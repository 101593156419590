import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { Player } from "../api/types";
import { SEARCH_PARAMS_PLAYER_ID } from "../constants";
import { usePlayer } from "../api/queries";
import { useSearchParams } from "react-router-dom";

export interface PlayerContextInterface {
  playerInContext: Player | undefined;
  setPlayerInContext: Dispatch<SetStateAction<Player | undefined>>;
}

const defaultState = {
  playerInContext: undefined,
  setPlayerInContext: () => {},
};

const PlayerContext = createContext<PlayerContextInterface>(defaultState);

export const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const [playerInContext, setPlayerInContext] = useState<Player | undefined>();
  const playerQueryResult = usePlayer(
    !!Number(searchParams.get(SEARCH_PARAMS_PLAYER_ID)),
    Number(searchParams.get(SEARCH_PARAMS_PLAYER_ID))
  );

  useEffect(() => {
    if (playerQueryResult.data) {
      setPlayerInContext(playerQueryResult.data as Player);
    }
  }, [playerQueryResult.data]);

  const value = {
    playerInContext: playerInContext,
    setPlayerInContext: setPlayerInContext,
  };

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};

export const usePlayerContext = () => useContext(PlayerContext);
