import { Avatar, Container, Stack, Typography } from "@mui/material";
import { GridListColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";

import { EMPTY_FIELD } from "../../constants";

export const ListColDef: GridListColDef = {
  field: "listColumn",
  renderCell: ListViewCell,
};

export default function ListViewCell(props: GridRenderCellParams) {
  const { row } = props;

  return (
    <Container
      sx={{
        minWidth: "100vw",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        mt={0.5}
        sx={{
          alignItems: "center",
          height: "100%",
        }}
      >
        <Avatar src={row.image} />
        <Stack direction="column" sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography noWrap variant="subtitle2">
              {row.name}
            </Typography>

            <Typography noWrap variant="caption">
              {row.nationality ? row.nationality : EMPTY_FIELD}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography noWrap variant="caption">
              {row.team ? row.team : EMPTY_FIELD}
            </Typography>

            <Typography noWrap variant="caption">
              {row.age ? row.age : EMPTY_FIELD}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
