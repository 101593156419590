import { Box, useMediaQuery, useTheme } from "@mui/system";
import {
    ChartData,
    ChartDataset,
    Chart as ChartJS,
    ChartOptions,
    Colors,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Tooltip,
} from "chart.js";

import { Radar } from "react-chartjs-2";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Colors,
    Filler,
    Tooltip,
    Legend
);

export type PlayerMetricsRadarChartProps = {
    chartOptions: ChartOptions<"radar">;
    datasets: ChartDataset<"radar", number[]>[];
    statLabels: string[];
};

export const PlayerMetricsRadarChart = (props: PlayerMetricsRadarChartProps) => {
    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

    const data: ChartData<"radar", number[], unknown> = {
        labels: props.statLabels,
        datasets: props.datasets,
    };

    return <Box
        sx={{
            display: "flex",
            flexGrow: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "inherit",
            height: isScreenSmall ? '50vh' : '100vh'
        }}
    >
        <Radar data={data} options={props.chartOptions} />
    </Box>

}

export default PlayerMetricsRadarChart;
