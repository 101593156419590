import { Badge, Box, Link, ListItemText, MenuItem, Stack } from "@mui/material";
import {
  formatDateTimeWithoutSecondsToLocal,
  formatStringToLocalDate,
} from "../utils/dates";

import { EmptyStateOverlay } from "../components/EmptyStateOverlay";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import TimeAgo from "javascript-time-ago";
import { useNotificationsContext } from "../contexts/NotificationContext";
import { useSearchParams } from "react-router-dom";

interface NotificationsProps {
  handleNotificationsClose?: (
    event: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void | undefined;
}

export default function Notifications(props: NotificationsProps) {
  const [, setSearchParams] = useSearchParams();
  const { notifications, notificationsAvailable } = useNotificationsContext();
  const timeAgo = new TimeAgo("en-US");

  return (
    <Box sx={{ maxWidth: "400px" }}>
      {notifications.map((notification) => {
        return (
          <MenuItem
            key={`${notification.action_time}-${notification.content}`}
            onClick={(event) => {
              setSearchParams(undefined);
              if (props.handleNotificationsClose) {
                props.handleNotificationsClose(event, "backdropClick");
              }
            }}
            sx={{
              cursor: notification.type === "note" ? "pointer" : "default",
              width: "100%",
            }}
          >
            <Badge
              invisible={!(notificationsAvailable && notification.isNew)}
              color="secondary"
              badgeContent=" "
              variant="dot"
              sx={{ width: "100%" }}
            >
              <ListItemText
                primary={
                  <Link
                    href={`${window.location.href}${notification.link}`}
                    variant="caption"
                  >
                    {notification.content}
                  </Link>
                }
                secondary={
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      {formatDateTimeWithoutSecondsToLocal(
                        notification.action_time
                      )}
                    </Box>

                    <Box>
                      {timeAgo.format(
                        formatStringToLocalDate(notification.action_time)
                      )}
                    </Box>
                  </Stack>
                }
                primaryTypographyProps={{
                  variant: "caption",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  noWrap: true,
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  noWrap: true,
                }}
                style={{
                  whiteSpace: "pre-line",
                }}
              />
            </Badge>
          </MenuItem>
        );
      })}

      {(!notifications || notifications?.length === 0) && (
        <Box p={1}>
          <EmptyStateOverlay
            icon={
              <NotificationsNoneIcon fontSize="small" sx={{ opacity: 0.26 }} />
            }
            content="No notifications"
          />
        </Box>
      )}
    </Box>
  );
}
