const Common = {
  typography: {
    david: {
      lineHeight: 1.6,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Avenir Next, Arial",
    },
    fontSize: 18,
    fontFamily: "Avenir Next, Arial",
  },
  transitions: {
    duration: {
      // https://mui.com/material-ui/customization/transitions/#durations
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 125,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
};

export default Common;
