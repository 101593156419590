import { createContext, useContext } from "react";

import { SendMessage } from "react-use-websocket/dist/lib/types";
import { useQueryClient } from "react-query";
import useWebSocket from "react-use-websocket";

const WEB_SOCKET_URL = process.env.REACT_APP_API_URL_WS || "";

const defaultState = {
  sendMessage: () => {},
};

interface WebSocketContextInterface {
  sendMessage: SendMessage;
}

export const WebSocketContext =
  createContext<WebSocketContextInterface>(defaultState);

const webSocketUrl = WEB_SOCKET_URL + "/websockets/";

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const { sendMessage } = useWebSocket(webSocketUrl, {
    share: true,
    onMessage: (event: MessageEvent) => {
      console.log(`WebSocket event | ${event?.data}`);
      const data = event.data;
      queryClient.invalidateQueries(data);
    },
  });

  const value = { sendMessage };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
