import { Box, Divider } from "@mui/material";

import { MatchReport } from "../../api/types";
import { MatchReportItem } from "./MatchReportItem";

interface MatchReportsProps {
  matchReports: MatchReport[];
}

export const MatchReports = (props: MatchReportsProps) => {
  return (
    <Box>
      {props.matchReports.map((mr) => {
        return (
          <Box key={mr.id} textAlign={"center"}>
            <MatchReportItem report={mr} />
            <Divider />
          </Box>
        );
      })}
    </Box>
  );
};
