import {
    BarElement,
    CategoryScale,
    Chart,
    ChartData,
    ChartDataset,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { Bar } from "react-chartjs-2";

Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export type PlayerMetricBarChartProps = {
    chartOptions: ChartOptions<"bar">;
    datasets: ChartDataset<"bar", number[]>[];
    statLabels: string[];
};

const PlayerMetricsBarChart = (props: PlayerMetricBarChartProps) => {
    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

    const data: ChartData<"bar", number[], unknown> = {
        labels: props.statLabels,
        datasets: props.datasets,
    };

    // console.debug(data);
    return <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: '100%',
            height: isScreenSmall ? '50vh' : '100vh',
        }}
    >
        <Bar options={props.chartOptions} data={data} />
    </Box>

}

export default PlayerMetricsBarChart;
