import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

import styled from "@emotion/styled";
import { useThemeContext } from "../contexts/CustomThemeContext";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => {
  const { theme } = useThemeContext();
  return {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  };
});
