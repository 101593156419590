import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

interface CloseButtonProps {
  onClick: () => void;
  position: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export default function CloseButton(props: CloseButtonProps) {
  return (
    <IconButton
      sx={{
        position: props.position,
        top: props.top ? props.top : "",
        bottom: props.bottom ? props.bottom : "",
        left: props.left ? props.left : "",
        right: props.right ? props.right : "",
      }}
      onClick={props.onClick}
      size="small"
      color="secondary"
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>
  );
}
