import { Box } from "@mui/material";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import { useAuth } from "../api/queries";

export interface TableauDashboardParams {
  [key: string]: string;
}

interface TableauDashboardProps {
  url: string;
  alignContent?: string;
  alignItems?: string;
  justifyContent?: string;
  params?: TableauDashboardParams;
  pl?: number;
  pr?: number;
}

export default function TableauDashboard(props: TableauDashboardProps) {
  // CONTEXT
  const auth = useAuth();
  const marginTop = 2;
  const padding = 12;

  return auth.data?.tableau_jwt ? (
    <Box
      id="tableau"
      key="tableauBox"
      overflow="auto"
      mt={marginTop}
      sx={{
        display: "flex",
        alignItems: props.alignItems ? props.alignItems : "center",
        alignContent: props.alignContent ? props.alignContent : "center",
        justifyContent: props.justifyContent ? props.justifyContent : "center",
        pl: props.pl !== undefined ? props.pl : padding,
        pr: props.pr !== undefined ? props.pr : padding,
      }}
    >
      <TableauEmbed
        key="tableauEmbed"
        version="3.5.0"
        sourceUrl={props.url}
        // width={width}
        token={auth.data?.tableau_jwt}
      >
        {props.params &&
          Object.keys(props.params).map((key: string) => {
            // Capitalize first letter to match parameter casing
            const value = props.params ? `${props.params[key]}` : "";
            key = key.charAt(0).toUpperCase() + key.slice(1);
            // console.debug(`${key}: ${value}`);

            // eslint-disable-next-line react/jsx-key
            return <custom-parameter name={key} value={value} />;
          })}
      </TableauEmbed>
    </Box>
  ) : (
    <></>
  );
}
