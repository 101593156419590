import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";

import { DepthChartPlayer } from "../../../api/types";
import { MenuList } from "@mui/material";
import PitchPlayerDraggableListItem from "./PitchPlayerDraggableListItem";
import { useThemeContext } from "../../../contexts/CustomThemeContext";
import { useUpdateDepthChartPlayer } from "../../../api/mutations";

const PLAYERS_PER_POSITION = 5;
const PLAYER_IN_LIST_HEIGHT = 36;
const PLAYER_LIST_MAX_HEIGHT = PLAYER_IN_LIST_HEIGHT * PLAYERS_PER_POSITION;
const PLAYER_LIST_MAX_WIDTH = 250;
const WHITE = "#fff";

interface PitchPlayerDraggableListProps {
  depthChartId: number;
  position: string;
  updateDepthChartPlayers: (
    depthChartPlayers: DepthChartPlayer[] | undefined
  ) => void;
  players: DepthChartPlayer[];
  editMode: boolean;
}

export default function PitchPlayerDraggableList(
  props: PitchPlayerDraggableListProps
) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // MUTATIONS
  const updateDepthChartPlayer = useUpdateDepthChartPlayer();

  // VARIABLES
  const BORDER = `1px solid ${theme.palette.primary.main}`;

  // STATES

  // FUNCTIONS
  const reorder = (
    startIndex: number,
    endIndex: number
  ): DepthChartPlayer[] | undefined => {
    const reordered = Array.from(props.players);
    const [removed] = reordered.splice(startIndex, 1);
    reordered.splice(endIndex, 0, removed);

    reordered.forEach((p, index) => {
      p["rank"] = index;
    });

    return reordered && reordered.length > 0 ? reordered : undefined;
  };

  const dragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const reordered = reorder(source.index, destination.index);

    // maintain order on the front end to maintain reordering while update is done in backend
    props.updateDepthChartPlayers(reordered);

    const player = props.players[source.index];

    player.rank = destination.index;

    updateDepthChartPlayer.mutate(player);
  };

  // USEEFFECTS

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable
        droppableId={`depth-chart-droppable-${props.depthChartId}-${props.position}`}
      >
        {(provided) => (
          <MenuList
            ref={provided.innerRef}
            {...provided.droppableProps}
            dense
            disablePadding
            disableListWrap={true}
            sx={{
              backgroundColor: WHITE,
              border: BORDER,
              borderRadius: 1,
              maxWidth: PLAYER_LIST_MAX_WIDTH,
              marginTop: 0.25,
              padding: 0.25,
              fontSize: 10,
              cursor: !props.editMode ? "pointer" : "grab",
              maxHeight: PLAYER_LIST_MAX_HEIGHT,
              overflowY:
                props.players.length > PLAYERS_PER_POSITION ? "scroll" : "auto",
            }}
          >
            {props.players
              .sort((a, b) => a?.rank - b?.rank)
              .map((p: DepthChartPlayer, index: number) => (
                <PitchPlayerDraggableListItem
                  depthChartId={props.depthChartId}
                  position={props.position}
                  player={p}
                  index={index}
                  editMode={props.editMode}
                  key={p.id}
                  height={PLAYER_IN_LIST_HEIGHT}
                  includeBorder={index !== props.players.length - 1}
                />
              ))}
            {provided.placeholder}
          </MenuList>
        )}
      </Droppable>
    </DragDropContext>
  );
}
