export function captializeFirstLetter(str: string) {
  if (typeof str != "string") return str;

  return (
    str.charAt(0).toUpperCase() +
    (str.length > 1 ? str.slice(1).toLowerCase() : "")
  );
}

export function includesIgnoreCaseAndDiacritics(
  str: string | undefined,
  substr: string | undefined
): boolean {
  if (!str || !substr) {
    return false;
  }
  const normalize = (s: string) =>
    s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return normalize(str.toLowerCase()).includes(normalize(substr.toLowerCase()));
}

export function toTitle(str: string) {
  let title = str.replace(/[^a-zA-Z0-9]/g, " ");
  title = title
    .split(" ")
    .map((w) => captializeFirstLetter(w))
    .join(" ");

  return title;
}
