import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MatchReport, Player, ReportQualityRating } from "../../api/types";
import { ReportGroupData, ReportQualityData } from "../../constants";
import { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BarChart } from "@mui/x-charts";
import { MatchReports } from "./MatchReports";
import { toTitle } from "../../utils/stringManipulation";
import { usePlayerMatchReports } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface PlayerScoutingProps {
  player: Player;
}

export const PlayerScouting = (props: PlayerScoutingProps) => {
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const playerMatchReportsQuery = usePlayerMatchReports(props.player?.id);
  const matchReports = playerMatchReportsQuery.data;

  // STATE
  const [profile, setProfile] = useState<string | undefined>();
  const [profiles, setProfiles] = useState<string[]>([]);
  const [profileMatchReports, setProfileMatchReports] = useState<
    MatchReport[] | undefined
  >();
  const [profileAverages, setProfileAverages] = useState<any[]>([]);
  const [groupAverages, setGroupAverages] = useState<any[] | null>(null);
  const [selectedProfileGroup, setSelectedProfileGroup] = useState<
    string | undefined | null
  >(null);

  // USEEFFECT
  // set available profiles
  useEffect(() => {
    if (matchReports && matchReports.length > 0) {
      // way to get distinct player profiles from all match_reports

      const ps = {} as { [key: string]: number };
      // group reports by profile
      matchReports.forEach((mr) => {
        if (!ps[mr.profile]) {
          ps[mr.profile] = 1;
        } else {
          ps[mr.profile]++;
        }
      });

      const keys = Object.keys(ps);
      if (keys) {
        setProfiles(keys);

        // set profile with most reports, if a profile is nother already selected
        if (!profile) {
          const profileWithMostReports = Object.entries(ps).reduce(
            (max, p) => (max[1] > p[1] ? max : p),
            ["init", -1]
          );
          setProfile(profileWithMostReports[0]);
        }
      }
    }
  }, [matchReports, profile]);

  // update profileMatchReports and calculate averages at the group level and quality level
  useEffect(() => {
    setSelectedProfileGroup(null);

    if (profile && matchReports) {
      const filteredMatchReports = matchReports.filter(
        (mr) => mr.profile === profile
      );
      setProfileMatchReports(filteredMatchReports);

      // flatten all qualities from each Match Report into a single list to iterate over easier
      const allRatings: ReportQualityRating[] = [];
      filteredMatchReports.forEach((mr) => {
        allRatings.push(...mr.ratings);
      });

      // GROUP by quality group
      const groups = allRatings.reduce((gs, r) => {
        (gs[r.quality.group] = gs[r.quality.group] || []).push(r);

        return gs;
      }, {} as { [key: string]: ReportQualityRating[] });

      // first iterate through each group and calculate total averages
      const groupData: ReportGroupData[] = [];
      Object.entries(groups).forEach((g) => {
        let groupRatingSum = 0;
        let groupFilled = 0;

        const groupRatings = g[1];

        groupRatings.forEach((q) => {
          if (q.rating) {
            groupRatingSum += q.rating;
            groupFilled++;
          }
        });

        // build data for each group
        const gData = {} as ReportGroupData;
        gData["group"] = toTitle(g[0]);
        gData["average"] = groupFilled
          ? Math.round((groupRatingSum / groupFilled) * 100) / 100
          : 0;
        gData["filled"] = groupFilled;
        gData["total"] = groupRatings.length;

        // and now GROUP by quality key within the group
        // iterate through each quality key and calculate averages
        const qualities = groupRatings.reduce((rqs, r) => {
          (rqs[r.quality.key] = rqs[r.quality.key] || []).push(r);

          return rqs;
        }, {} as { [key: string]: ReportQualityRating[] });

        const qualityData: ReportQualityData[] = [];
        Object.entries(qualities).forEach((quality) => {
          let qualityRatingSum = 0;
          let qualityFilled = 0;

          quality[1].forEach((q) => {
            if (q.rating) {
              qualityRatingSum += q.rating;
              qualityFilled++;
            }
          });

          const qData = {} as ReportQualityData;
          qData["abb"] = quality[1][0].quality.abbreviation;
          qData["label"] = quality[1][0].quality.label;
          qData["average"] = qualityFilled
            ? Math.round((qualityRatingSum / qualityFilled) * 100) / 100
            : 0;
          qData["filled"] = qualityFilled;
          qData["total"] = quality[1].length;

          qualityData.push(qData);
        });

        // attach quality averages to group
        gData["qualities"] = qualityData;

        groupData.push(gData);
      });

      setProfileAverages(groupData);
    }
  }, [profile, matchReports]);

  useEffect(() => {
    if (selectedProfileGroup) {
      setGroupAverages(
        profileAverages.find((g) => g.group === selectedProfileGroup)[
          "qualities"
        ]
      );
    }
  }, [selectedProfileGroup, profileAverages]);

  return (
    <Box>
      {!isScreenSmall && (
        <Box>
          {profile && (
            <>
              <Box display={"flex"} justifyContent={"center"}>
                <Select
                  sx={{ width: "200px" }}
                  value={profile || ""}
                  onChange={(e: SelectChangeEvent) => {
                    setProfile(e.target.value);
                  }}
                >
                  {profiles.map((p) => {
                    return (
                      <MenuItem key={p} value={p}>
                        {toTitle(p)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box
                onClick={() => setSelectedProfileGroup(null)}
                display={"flex"}
                justifyContent={"left"}
                alignItems="center"
                ml={6}
                sx={{ cursor: "pointer" }}
                minHeight={31}
              >
                {!isScreenSmall && selectedProfileGroup && (
                  <>
                    <ArrowBackIcon />
                    <Typography>Back</Typography>
                  </>
                )}
              </Box>
            </>
          )}

          {(profileMatchReports && profileMatchReports.length >= 5 && (
            <Box display={"flex"} justifyContent={"center"} m={0} p={0}>
              {(!selectedProfileGroup && (
                <BarChart
                  height={400}
                  width={1500}
                  dataset={profileAverages}
                  xAxis={[
                    {
                      label: "Quality Groups",
                      scaleType: "band",
                      dataKey: "group",
                    },
                  ]}
                  yAxis={[
                    {
                      max: 6,
                      label: "Average Rating (1-6)",
                    },
                  ]}
                  series={[
                    {
                      dataKey: "average",
                      label: "Group Average Rating",
                      color: theme.palette.primary.main,
                    },
                  ]}
                  onAxisClick={(event, d) => {
                    if (d?.axisValue !== "Game Impact") {
                      setSelectedProfileGroup(d?.axisValue as string);
                    }
                  }}
                  // barLabel={"value"}
                  // slots={{
                  //   barLabel: (props) => {
                  //     return (
                  //       <Box>
                  //         <Typography sx={{ color: "white" }}>hello</Typography>
                  //       </Box>
                  //     );
                  //   },
                  // }}
                  // slotProps={{
                  //   barLabel: {},
                  // }}
                  // skipAnimation={true}
                />
              )) || (
                <BarChart
                  height={400}
                  width={1500}
                  dataset={groupAverages || []}
                  xAxis={[
                    {
                      label: `${selectedProfileGroup}`,
                      scaleType: "band",
                      dataKey: "abb",
                      valueFormatter: (abb, context) => {
                        return context.location === "tick"
                          ? abb
                          : groupAverages?.find((ga) => ga.abb === abb)
                          ? groupAverages?.find((ga) => ga.abb === abb).label
                          : abb;
                      },
                    },
                  ]}
                  yAxis={[
                    {
                      max: 6,
                      label: "Average Rating (1-6)",
                    },
                  ]}
                  series={[
                    {
                      dataKey: "average",
                      label: "Quality Average Rating",
                      color: theme.palette.info.main,
                    },
                  ]}
                />
              )}
            </Box>
          )) || (
            <Box p={6}>
              <Typography textAlign={"center"} color="gray">
                Player must have 5 Match Reports for this Profile to see total
                averages
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {profile && profileMatchReports && (
        <>
          {!isScreenSmall && (
            <Divider>
              <Typography fontWeight={500}>MATCH REPORTS</Typography>
            </Divider>
          )}
          <Box
            sx={{
              overflow: "scroll",
              height: isScreenSmall ? "500px" : "600px",
            }}
          >
            <MatchReports matchReports={profileMatchReports} />
          </Box>
        </>
      )}
    </Box>
  );
};
