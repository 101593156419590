import {
  DateValidationError,
  DesktopDatePicker,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";

import { getUTCDate } from "../utils/dates";

export interface DatePickerProps {
  label: string;
  value: Date | undefined | null;
  onChange: (
    value: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  size?: string;
  disableFuture?: boolean | undefined;
  onError?: (error: DateValidationError) => void;
}

export default function DefaultDatePicker(props: DatePickerProps) {
  const d = props.value ? getUTCDate(new Date(props.value)) : null;

  return (
    <DesktopDatePicker
      disableFuture={
        props.disableFuture === undefined ? true : props.disableFuture
      }
      label={props.label}
      format="MM/dd/yyyy"
      value={d}
      onChange={props.onChange}
      onError={props.onError}
    />
  );
}
