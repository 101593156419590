/* eslint-disable */
import {
  MATCH_REPORTS_ROUTE,
  NOTES_ROUTE,
  SCOUTING_PLAYERS_ROUTE,
  SCOUTING_PLAYER_RATINGS_ROUTE,
} from "../../routes";

import DepthChartMenu from "./DepthChartMenu";
import { Folder } from "../../api/types";
import { HOTKEYS } from "../HotKeys";
import ListsMenu from "./ListsMenu";
import { TreeItem } from "../TreeItem";
import TreeItemLink from "../TreeItemLink";
import { useUserContext } from "../../contexts/UserContext";

export default function ScoutingMenu(props: { folders: Folder[] | null }) {
  return (
    <TreeItem itemId="scouting" label="SCOUTING">
      <DepthChartMenu />

      <ListsMenu folders={props.folders} />

      <TreeItemLink
        to={SCOUTING_PLAYERS_ROUTE}
        label="PLAYERS"
        nested
        tooltipTitle={HOTKEYS["players"].tooltip}
      />

      <TreeItemLink
        to={SCOUTING_PLAYER_RATINGS_ROUTE}
        label="PLAYER RATINGS"
        nested
      />

      <TreeItemLink
        to={NOTES_ROUTE}
        label="NOTES"
        nested
        tooltipTitle={HOTKEYS["notes"].tooltip}
      />

      <TreeItemLink
        to={MATCH_REPORTS_ROUTE}
        label="REPORTS"
        nested
        tooltipTitle={HOTKEYS["match_reports"].tooltip}
      />
    </TreeItem>
  );
}
