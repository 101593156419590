import { Fab, Tooltip, useTheme } from "@mui/material";
import { SCOUTING_DEPTH_ROUTE, SCOUTING_ROOT_ROUTE } from "../../routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DepthChart from "./depth_charts";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Player } from "../../api/types";
import PlayerProfileDialog from "../../components/dialogs/PlayerProfileDialog";
import Players from "../players";
/* eslint-disable */
import { useMatchReportDialogContext } from "../../contexts/MatchReportDialogContext";
import { useTeamContext } from "../../contexts/TeamContext";

const Scouting = () => {
  const theme = useTheme();
  const location = useLocation();
  const { handleCreateReportClick } = useMatchReportDialogContext();
  const { team } = useTeamContext();
  const navigate = useNavigate();

  const [el, setEl] = useState<JSX.Element>();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [
      {
        id: "group-select",
        field: "grouping",
        operator: "isEmpty",
        value: undefined,
      },
      {
        id: "player-name-search",
        field: "player_name",
        operator: "contains",
        value: "",
      },
    ],
    logicOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  } as GridFilterModel);
  const [playerInContext, setPlayerInContext] = useState<
    Player | null | undefined
  >();
  const [playerProfileModalOpen, setPlayerProfileModalOpen] =
    useState<boolean>(false);

  // EFFECTS
  useEffect(() => {
    setFilterModel({
      items: [
        {
          id: "group-select",
          field: "grouping",
          operator: "isEmpty",
          value: undefined,
        },
        {
          id: "player-name-search",
          field: "player_name",
          operator: "contains",
          value: "",
        },
      ],
      logicOperator: "and",
      quickFilterLogicOperator: "and",
      quickFilterValues: [],
    } as GridFilterModel);
  }, [team]);

  useEffect(() => {
    // console.debug(location.pathname);

    switch (location.pathname) {
      case SCOUTING_DEPTH_ROUTE:
        setEl(<DepthChart />);
        break;
      default:
        setEl(<Players />);
    }
  }, [navigate, location.pathname, filterModel, playerInContext]);

  return (
    <>
      {playerInContext && <PlayerProfileDialog />}

      {/* Dynamically render element */}
      {el}

      {location.pathname == SCOUTING_ROOT_ROUTE && (
        <Tooltip
          disableFocusListener
          key="create-scouting-report-tooltip"
          title="CREATE SCOUTING REPORT"
        >
          <Fab
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => {
              handleCreateReportClick(undefined);
            }}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
              position: "absolute",
              top: 75,
              right: 25,
            }}
          >
            <NoteAddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

export default Scouting;
