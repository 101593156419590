import { ConnectDragSource, DragSourceMonitor, useDrag } from "react-dnd";
import { ElementType, forwardRef, useCallback, useRef } from "react";
import {
  TreeItem as MuiTreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import { Tooltip, alpha, styled } from "@mui/material";

import { LIST } from "./ItemTypes";

interface DraggableTreeItemProps extends TreeItemProps {
  draggable?: boolean;
  tooltip?: string;
  selected?: boolean;
  icon?: ElementType<any> | undefined;
}

// eslint-disable-next-line react/display-name
const DraggableTreeItem = forwardRef<HTMLLIElement, DraggableTreeItemProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ...props }, ref) => {
    const elRef = useRef(null);

    const [{ isDragging }, drag] = useDrag({
      type: LIST,
      item: props.itemProp,
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
        isDragging: !!monitor.getItem(),
      }),
    });

    drag(elRef);

    const dragRef = useCallback(
      (elt: Element) => {
        elt?.addEventListener("focusin", (e) => {
          // Disable Treeview focus system which make draggable on TreeIten unusable
          // see https://github.com/mui-org/material-ui/issues/29518
          //   console.debug('stop propagation');
          e.stopImmediatePropagation();
        });
        drag(elt);
      },
      [drag]
    );

    // console.debug(`${props.itemId}: ${props.selected} | ${className}`);

    return (
      <Tooltip placement="right" title={props.tooltip ? props.tooltip : ""}>
        <MuiTreeItem
          ref={props.draggable ? (dragRef as ConnectDragSource) : undefined}
          draggable={props.draggable === true}
          // onDragStart={() => { console.debug('drag start')}}
          // onDragEnd={() => { console.debug('drag end')}}
          slots={{
            icon: props.icon ? props.icon : undefined,
          }}
          style={{
            ...props.style,
            ...(!isDragging && {
              "&:hover": {
                opacity: 0,
              },
            }),
          }}
          {...props}
        />
      </Tooltip>
    );
  }
);

export const TreeItem = styled(DraggableTreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  position: "relative",
  [`& .${treeItemClasses.content}`]: {
    // flexDirection: 'row-reverse',
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.25),
    padding: theme.spacing(0.5),
    fontWeight: 500,
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
    },
    [`&.Mui-expanded `]: {
      "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon":
        {
          color:
            theme.palette.mode === "light"
              ? theme.palette.primary.main
              : theme.palette.secondary.dark,
        },
      "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        left: "16px",
        top: "44px",
        height: "calc(100% - 48px)",
        width: "1.5px",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
      },
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      color:
        theme.palette.mode === "light" ? theme.palette.secondary.main : "white",
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.info.main
          : theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(1.25),
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    [`& .${treeItemClasses.content}`]: {
      fontWeight: 500,
    },
  },
}));
