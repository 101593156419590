import { Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { Note, User } from "../api/types";
import { SEARCH_PARAMS_NOTE_ID, SEARCH_PARAMS_PLAYER_ID } from "../constants";

import { EmptyStateOverlay } from "./EmptyStateOverlay";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import NoteCard from "./NoteCard";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import { useCallback } from "react";
import { useConstantsContext } from "../contexts/ConstantsContext";
import { useSearchParams } from "react-router-dom";
import { useThemeContext } from "../contexts/CustomThemeContext";

export type PlayerNotesProps = {
  dialog: boolean;
  notes: Note[] | undefined;
};

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export default function PlayerNotes(props: PlayerNotesProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { users } = useConstantsContext();
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  const sortPlayerNotes = useCallback((notes?: Note[]): Note[] => {
    if (notes) {
      return [...notes].sort((a, b) =>
        a["updated_at"] < b["updated_at"]
          ? 1
          : b["updated_at"] < a["updated_at"]
          ? -1
          : 0
      );
    } else {
      return [];
    }
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent={props.dialog ? "center" : "flex-start"}
      alignContent="flex-start"
      alignItems={props.dialog ? "center" : "flex-start"}
      spacing={0}
      pt={1}
      pl={!props.dialog && isScreenSmall ? 0 : "3%"}
      pr={!props.dialog && isScreenSmall ? 0 : "3%"}
    >
      {(!props.notes || props.notes?.length <= 0) && (
        <EmptyStateOverlay
          content="No notes found"
          icon={
            <LiveHelpOutlinedIcon
              sx={{ opacity: 0.26, width: "4rem", height: "4rem" }}
            />
          }
        />
      )}

      {props.notes &&
        sortPlayerNotes(props.notes).map((note: Note) => {
          const highlighted =
            Number(searchParams.get(SEARCH_PARAMS_NOTE_ID)) === note.id;

          const action =
            note?.updated_at > note.created_at ? "updated" : "created";

          const user = users?.find((user: User) => {
            return action === "created"
              ? user.email === note.created_by
              : user.email === note.updated_by;
          });
          const actionBy = user ? user.name : note.created_by;
          const when =
            action === "updated"
              ? timeAgo.format(moment.utc(note.updated_at).toDate())
              : timeAgo.format(moment.utc(note.created_at).toDate());

          const onNoteLinkClick = () => {
            searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(note.player_id));
            searchParams.set(SEARCH_PARAMS_NOTE_ID, String(note.id));
            setSearchParams(searchParams);
          };

          if (props.dialog) {
            return (
              <NoteCard
                key={note.id}
                note={note}
                highlighted={highlighted}
                actionBy={actionBy}
              />
            );
          }

          return (
            <Typography
              key={note.content}
              noWrap
              overflow="hidden"
              textOverflow="ellipses"
              variant="caption"
              width="100%"
            >
              • <i>{actionBy}</i> {action}{" "}
              {action === "created" ? "a" : "existing"} note {when}:
              <Link
                onClick={onNoteLinkClick}
                sx={{ textOverflow: "ellipsis", cursor: "pointer" }}
              >
                {note.content}
              </Link>
            </Typography>
          );
        })}
    </Stack>
  );
}
