import { Dispatch, SetStateAction, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { PlayerList } from "../../api/types";
import TextField from "@mui/material/TextField";
import { useRenameListMutation } from "../../api/mutations";

export type RenameListDialogProps = {
  open: boolean;
  list: PlayerList;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectionModel?: GridRowSelectionModel;
};

export default function RenameListDialog(props: RenameListDialogProps) {
  // STATE
  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(props.list.name);
  }, [props.list.name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // MUTATIONS
  const updateListNameMutation = useRenameListMutation();

  return (
    <Dialog
      key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog`}
      open={props.open}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <CloseButton
        key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-close-btn`}
        top={10}
        left={10}
        position="absolute"
        onClick={() => props.setOpen(false)}
      />

      <DialogTitle
        key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-title`}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        Rename List
      </DialogTitle>

      <DialogContent
        key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-content`}
      >
        <DialogContentText
          key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-content-box`}
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-content-box-text`}
            margin="dense"
            id="value"
            label="List Name"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={1}
            value={value}
            onChange={handleChange}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions
        key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-actions`}
      >
        <Button
          key={`${props.list.folder_id}-${props.list.id}-${props.list.name}-rename-dialog-actions-btn`}
          onClick={() => {
            const newList = props.list;
            newList.name = value as string;
            updateListNameMutation.mutate(newList);
            props.setOpen(false);
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
}
