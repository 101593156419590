// Tokens seems to have a 1 hour expiration
// Since access (and refresh) tokens expire in 1 hour from the time we get one
// we should check to see if the current epoch (plus some arbitrary buffer) is
// greater than or equal to the expiration time; this in turn means that there should
// be a forty five minute window in which we have the opportunity in the front end to
// refresh the access token

import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

export function isTokenExpired(oktaContext: IOktaContext) {
  const bufferValue = 30 * 60; // thirty minutes
  const nowPlusBuffer = Math.round(Date.now() / 1000) + bufferValue;
  return (
    oktaContext.authState?.accessToken?.expiresAt &&
    nowPlusBuffer >= oktaContext.authState?.accessToken?.expiresAt
  );
}

export async function checkRefreshToken(oktaContext: IOktaContext) {
  // console.debug("checking refresh token");
  if (isTokenExpired(oktaContext)) {
    console.debug("refreshing access token");
    const renewToken = await oktaContext.oktaAuth.token.renewTokens();
    oktaContext.oktaAuth.tokenManager.setTokens(renewToken);
  }
}
