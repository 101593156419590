import {
  SCOUTING_ACADEMY_PLAYER_RATING_LABELS,
  SCOUTING_COLLEGE_PLAYER_RATING_LABELS,
  SCOUTING_MLSNP_PLAYER_RATING_LABELS,
  SCOUTING_MLS_PLAYER_RATING_LABELS,
  SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
} from "../../constants";

const getRatingLabel = (value: number, ratings: string[]) => {
  return ratings[value];
};

const ratingFormatter = (value: number, ratings: string[]) => {
  const ratingLabel = getRatingLabel(value, ratings);
  return ratingLabel ? `${ratingLabel} (${value})` : "No Rating";
};

const getScoutingRatings = () => {
  let ratings: string[] = [...SCOUTING_MLS_PLAYER_RATING_LABELS];
  ratings = ratings.concat(
    ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
    ...SCOUTING_MLSNP_PLAYER_RATING_LABELS
  );
  ratings = ratings.concat(
    ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
    ...SCOUTING_MLSNP_PLAYER_RATING_LABELS,
    SCOUTING_COLLEGE_PLAYER_RATING_LABELS
  );
  ratings = ratings.concat(
    ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
    SCOUTING_MLSNP_PLAYER_RATING_LABELS,
    SCOUTING_COLLEGE_PLAYER_RATING_LABELS,
    SCOUTING_ACADEMY_PLAYER_RATING_LABELS
  );

  return ratings;
};

export const GetRatings = (): string[] => {
  return getScoutingRatings();
};

export const GetRatingFormatter = () => (value: number) => {
  return ratingFormatter(value, GetRatings());
};

export const GetRatingLabel = (rating: number): string => {
  return ratingFormatter(rating, GetRatings());
};

export const ordinalSuffixOf = (i: string): string => {
  let x: number = +i;
  let j = x % 10,
    k = x % 100;
  if (j === 1 && k !== 11) {
    return x + "st";
  }
  if (j === 2 && k !== 12) {
    return x + "nd";
  }
  if (j === 3 && k !== 13) {
    return x + "rd";
  }
  return x + "th";
};
