import { Player, ScoutedPlayer } from "../api/types";

import md5 from "md5";
import { v4 as uuidv4 } from "uuid";

export const getUniqueKeyPlayer = (player: Player): string => {
  const hash = md5(
    `${player.player_name}-${player.birthdate}-${player.nationality}`
  ).replace(" ", "-");
  return `${player.id}-${player.tm_id}-${player.competition_id}-${
    player.season_id
  }-${player.tss_team_id}-${player.team_name}-${player.primary_position}-${
    player.secondary_position
  }-${hash}-${uuidv4()}`.replace(" ", "-");
};

export const getUniqueKeyScoutedPlayer = (
  scoutedPlayer: ScoutedPlayer
): string => {
  const hash = md5(
    `${scoutedPlayer.tm_link}-${scoutedPlayer.birthdate}-${scoutedPlayer.nationality}`
  ).replace(" ", "-");
  return `${scoutedPlayer.id}-${scoutedPlayer.grouping}-${scoutedPlayer.team}-${hash}`;
};
