import { MatchReport, Player } from "../api/types";
import { createContext, useContext, useState } from "react";

import MutateMatchReportDialog from "../pages/scouting/Dialogs/MutateMatchReportDialog";
import QueryMatchReportDialog from "../pages/scouting/Dialogs/QueryMatchReportDialog";
import { SEARCH_PARAMS_REPORT_ID } from "../constants";
import { useSearchParams } from "react-router-dom";

interface MatchReportDialogProps {
  handleCreateReportClick: (player: Player | undefined) => void;
  handleReportClick: (report: MatchReport) => void;
}

const defaultState = {
  handleCreateReportClick: () => {},
  handleReportClick: () => {},
};

const MatchReportDialogContext =
  createContext<MatchReportDialogProps>(defaultState);

export const ScoutingReportDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // CONTEXT
  const [reportInContext, setReportInContext] = useState<MatchReport>();
  const [player, setPlayer] = useState<Player>();

  // STATE
  const [mutateDialogOpen, setMutateDialogOpen] = useState<boolean>(false);
  const [queryDialogOpen, setQueryDialogOpen] = useState<boolean>(false);

  const handleReportClick = (report: MatchReport) => {
    // console.debug(report);
    if (report) {
      searchParams.set(SEARCH_PARAMS_REPORT_ID, String(report.id));

      setSearchParams(searchParams);
      setReportInContext(report);
    }
    setQueryDialogOpen(true);
  };

  const handleCreateReportClick = (player: Player | undefined) => {
    searchParams.delete(SEARCH_PARAMS_REPORT_ID);
    setPlayer(player);
    setMutateDialogOpen(true);
  };

  const defaultValue = {
    handleCreateReportClick: handleCreateReportClick,
    handleReportClick: handleReportClick,
  };

  return (
    <MatchReportDialogContext.Provider value={defaultValue}>
      <MutateMatchReportDialog
        player={player}
        open={mutateDialogOpen}
        setOpen={setMutateDialogOpen}
        onClose={() => {
          setPlayer(undefined);
          setMutateDialogOpen(false);
        }}
      />

      <QueryMatchReportDialog
        player={player}
        open={queryDialogOpen}
        openMutateDialog={setMutateDialogOpen}
        report={reportInContext}
        setOpen={setQueryDialogOpen}
      />
      {children}
    </MatchReportDialogContext.Provider>
  );
};

export const useMatchReportDialogContext = () =>
  useContext(MatchReportDialogContext);
