import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import { useMemo, useState } from "react";

import { Grid2 as Grid } from "@mui/material";
import Rating from "@mui/material/Rating";
import { ReportQualityRating } from "../../api/types";
import StarIcon from "@mui/icons-material/Star";
import debounce from "lodash/debounce";
import { useThemeContext } from "../../contexts/CustomThemeContext";

const ratings: { [index: number]: string } = {
  1: "MLS 24-30",
  2: "MLS 17-23",
  3: "MLS 11-16",
  4: "MLS 4-10",
  5: "MLS DP",
  6: "MLS Best XI",
};

interface ReportQualityItemProps {
  id: string;
  rating: ReportQualityRating;
  updateRating: (quality: ReportQualityRating) => void;
  readOnly: boolean;
}

export default function ReportQualityItem(props: ReportQualityItemProps) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // HOOKS
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [hover, setHover] = useState<number>(-1);
  const [note, setNote] = useState<string>();

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${ratings[value]}`;
  }

  // Deboucing improves performance of typing a quality note. Previously, the updateQuality function would
  // be called after each keystroke and that would cause performance issues. Debouncing the function calls
  // has seemed to improve the performance a lot.
  const debouncedNoteChange = useMemo(
    () =>
      debounce((note: string) => {
        const updatedRating = {
          ...props.rating,
        };

        updatedRating.note = note;
        props.updateRating(updatedRating as ReportQualityRating);
      }, 1000),
    [props]
  );

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid key={`quality-rating-rating-${props.id}`} size={{ xs: 12, md: 5 }}>
        <Typography variant="caption">{props.rating.quality.label}</Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 7 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Rating
            size={isScreenSmall ? "small" : "medium"}
            readOnly={props.readOnly}
            name="hover-feedback"
            value={props.rating.rating || null}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              const updatedRating = {
                ...props.rating,
              };

              updatedRating.rating = newValue;
              props.updateRating(updatedRating as ReportQualityRating);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
            max={6}
          />

          <Box sx={{ ml: 1 }}>
            <Typography variant="caption" fontWeight="500">
              {(!props.readOnly &&
                ratings[
                  hover !== -1
                    ? hover
                    : props.rating.rating
                    ? props.rating.rating
                    : -1
                ]) ||
                ratings[props.rating.rating ? props.rating.rating : -1]}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {(!props.readOnly && (
        <TextField
          key={`quality-rating-note-${props.id}`}
          type="text"
          variant="outlined"
          rows={1}
          value={note}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNote(event.target.value);
            // const updatedQuality = {
            //   ...props.quality,
            // };

            // updatedQuality.note = note;
            // props.updateQuality(updatedQuality as ReportQualityRating);
            debouncedNoteChange(event.target.value);
          }}
          fullWidth
          size="small"
          slotProps={{
            input: {
              inputProps: {
                sx: { fontSize: "12px" },
              },
            },
          }}
        />
      )) || (
        <Typography variant="body2" textAlign="center" fontStyle="italic">
          {props.rating.note}
        </Typography>
      )}
    </Grid>
  );
}
