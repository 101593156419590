import "./App.css";

import {
  AlertProvider,
  SEVERITY_ERROR,
  useAlertContext,
} from "./contexts/AlertContext";
import { Box, Skeleton } from "@mui/material";
import CustomAppBar, { APPBAR_HEIGHT } from "./app_bar/Toolbar";
import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react"; // <-- Provider imports 'rollbar' for us
import { Helmet, HelmetProvider } from "react-helmet-async";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Suspense, useEffect, useState } from "react";
import { oktaConfig, rollbarConfig } from "./config";
import { useLocation, useNavigate } from "react-router-dom";

import { APIProvider } from "./contexts/APIContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import AppRoutes from "./AppRoutes";
import { AxiosError } from "axios";
import { ClubProvider } from "./contexts/ClubContext";
import { ConstantsProvider } from "./contexts/ConstantsContext";
import { CustomThemeProvider } from "./contexts/CustomThemeContext";
import { DepthChartProvider } from "./contexts/DepthChartContext";
import { DndProvider } from "react-dnd";
import { EmptyStateOverlay } from "./components/EmptyStateOverlay";
import ErrorIcon from "@mui/icons-material/Error";
import { GroupProvider } from "./contexts/GroupContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HotkeysProvider } from "react-hotkeys-hook";
import { ListProvider } from "./contexts/ListContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NotesDialog from "./components/dialogs/NotesDialog";
import { NotificationsProvider } from "./contexts/NotificationContext";
import OAuthSignInPage from "./pages/signin";
import { OktaAuthProvider } from "./contexts/OktaAuthContext";
import PlayerProfileDialog from "./components/dialogs/PlayerProfileDialog";
import { PlayerProvider } from "./contexts/PlayerContext";
import { ScoutingReportDialogProvider } from "./contexts/MatchReportDialogContext";
import { Security } from "@okta/okta-react";
import { TeamProvider } from "./contexts/TeamContext";
import { UserProvider } from "./contexts/UserContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { WindowSizeProvider } from "./contexts/WindowSizeContext";
import { datadogRum } from "@datadog/browser-rum";
import { useCacheBuster } from "react-cache-buster";

// console.log(process.env);
const oktaAuth = new OktaAuth(oktaConfig);

const ERROR_BOUNDARY_HTTP_CODE = 500;
console.debug(`ERROR_BOUNDARY_HTTP_CODE: ${ERROR_BOUNDARY_HTTP_CODE}`);

const isProduction = process.env.NODE_ENV === "production";
const title = isProduction ? "The Pond" : "The Pond (dev)";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: false,
    },
  },
});

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCacheStatus } = useCacheBuster();
  const { setAlertOptions } = useAlertContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuthRequiredDialogOpen] = useState<boolean>(false);

  const isAuthenticated =
    oktaAuth.authStateManager.getAuthState()?.isAuthenticated;

  useEffect(() => {
    const defaultOptions: DefaultOptions = {
      queries: {
        enabled: isAuthenticated,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        onSettled: (data: any, error: any) => {
          if (error) {
            if (error instanceof AxiosError) {
              //   console.debug(error);

              if (Number(error?.response?.status) >= ERROR_BOUNDARY_HTTP_CODE) {
                setAlertOptions({
                  message:
                    data?.alertMessage ||
                    `${error.message} | ${error.response?.data.detail}`,
                  severity: data?.alertSeverity || SEVERITY_ERROR,
                  title: data?.alertTitle || "an error occurred",
                });
              }
            } else {
              setAlertOptions({
                message: data?.alertMessage || error.message,
                severity: data?.alertSeverity || SEVERITY_ERROR,
                title: data?.alertTitle || "an error occurred",
              });
            }
          }
        },
        useErrorBoundary: (error) => {
          if (error instanceof AxiosError) {
            console.info(
              `error.response.status: ${error?.response?.status} | error.message: ${error.message}`
            );

            if (error?.response?.status === 401) {
              console.log("401 received; signing out");
              oktaAuth.signOut();
            }
          }

          return false;
        },
      },
      mutations: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSettled: (data: any, error: any, variables: any, context: any) => {
          if (error && error.status >= 400) {
            setAlertOptions({
              message: error?.response?.data?.detail
                ? error.response.data.detail
                : error.message,
              severity: SEVERITY_ERROR,
            });
          }
        },
        useErrorBoundary: () => {
          return false;
        },
        retry: false,
      },
    };
    queryClient.setDefaultOptions(defaultOptions);
  }, [setAlertOptions, isAuthenticated]);

  // Check to see if there's a new version when the window is refocused
  window.addEventListener("focus", checkCacheStatus);
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", location));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await oktaAuth.signInWithRedirect();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredDialogOpen(true);
    }
  };

  useEffect(() => {
    async function startSessionReplay() {
      if (isAuthenticated) {
        const user = await oktaAuth.getUser();

        if (user) {
          datadogRum.setUser(user);

          if (isProduction) {
            datadogRum.startSessionReplayRecording();
          }
        }
      }
    }

    if (isProduction) {
      try {
        startSessionReplay();
      } catch (e) {
        console.error(e);
      }
    }
  }, [isAuthenticated]);

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <HelmetProvider>
        <Helmet title={title} />
      </HelmetProvider>

      <RollbarProvider config={rollbarConfig}>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <OAuthSignInPage />
          <OktaAuthProvider>
            <ErrorBoundary
              fallbackUI={({
                error,
              }: {
                error: Error | null;
                resetError: () => void;
              }): JSX.Element => {
                return (
                  <EmptyStateOverlay
                    content="An unexpected error occurred. Try refreshing your page."
                    icon={
                      <ErrorIcon
                        color="error"
                        sx={{ width: "4rem", height: "4rem" }}
                      />
                    }
                    height="100vh"
                  />
                );
              }}
            >
              <HotkeysProvider>
                <APIProvider>
                  <DndProvider debugMode={true} backend={HTML5Backend}>
                    <UserProvider>
                      <ConstantsProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <WindowSizeProvider>
                            <WebSocketProvider>
                              <ClubProvider>
                                {" "}
                                {/* must be above of CustomThemeProvider */}
                                <CustomThemeProvider>
                                  <TeamProvider>
                                    {" "}
                                    {/* must be above of PlayerProvider */}
                                    <ListProvider>
                                      <DepthChartProvider>
                                        <PlayerProvider>
                                          <GroupProvider>
                                            <Suspense
                                              fallback={
                                                <Skeleton
                                                  variant="rectangular"
                                                  width="100%"
                                                />
                                              }
                                            >
                                              <NotificationsProvider>
                                                <AlertProvider>
                                                  <ScoutingReportDialogProvider>
                                                    <CustomAppBar />
                                                    <Box
                                                      sx={{
                                                        margin: 0,
                                                        border: 0,
                                                        padding: 0,
                                                        maxHeight: `calc(100vh - ${APPBAR_HEIGHT})`,
                                                      }}
                                                    >
                                                      <PlayerProfileDialog />
                                                      <NotesDialog />
                                                      <AppRoutes />
                                                    </Box>
                                                  </ScoutingReportDialogProvider>
                                                </AlertProvider>
                                              </NotificationsProvider>
                                            </Suspense>
                                          </GroupProvider>
                                        </PlayerProvider>
                                      </DepthChartProvider>
                                    </ListProvider>{" "}
                                  </TeamProvider>
                                </CustomThemeProvider>
                              </ClubProvider>{" "}
                            </WebSocketProvider>
                          </WindowSizeProvider>
                        </LocalizationProvider>
                      </ConstantsProvider>
                    </UserProvider>
                  </DndProvider>
                </APIProvider>
              </HotkeysProvider>
            </ErrorBoundary>
          </OktaAuthProvider>
        </Security>
      </RollbarProvider>
    </QueryClientProvider>
  );
};

export default App;
