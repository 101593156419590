import { Box, Grid, Stack, Typography } from "@mui/material";

import { NoRowsFoundIcon } from "../components/CustomIcons";

export interface EmptyStateOverlayProps {
  content?: string,
  icon?: JSX.Element,
  width?: string,
  height?: string
}

export function EmptyStateOverlay(
  props: EmptyStateOverlayProps
): JSX.Element {
  return (
    <Grid height={props?.height ? props.height : "100%"} width={props?.width ? props.width : "100%"} display="flex" justifyContent="center" alignItems="center" alignContent="center">
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: props.width ? props.width : "100%",
            height: props.height ? props.height : "100%",
        }}
      >
        <Box>
            {props?.icon ? props.icon : <NoRowsFoundIcon />}
        </Box>
        <Typography
            alignItems="center"
            variant="body2"
        >
            {props?.content}
        </Typography>
      </Stack>
    </Grid>
  );
}
