import { Avatar, Link } from "@mui/material";
import {
  EURCurrencyFormatter,
  USDCurrencyFormatter,
} from "../../utils/currency";
import {
  EXTRA_EXTRA_LARGE_WIDTH,
  EXTRA_LARGE_WIDTH,
  LARGE_WIDTH,
  MEDIUM_WIDTH,
  SMALL_WIDTH,
  withStringOperators,
  withoutStringOperators,
} from "../../utils/dataGridHelpers";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";

import { CellWithColor } from "./CellWithColor";
import { STAT_INFO } from "../../constants";
import { clsx } from "clsx";
import { getUTCDate } from "../../utils/dates";
import { useMemo } from "react";

export const hiddenByDefault: GridColDef[] = [
  {
    field: "mls_eligible",
    filterable: true,
    headerName: "MLS Eligible",
    width: SMALL_WIDTH,
    type: "boolean",
    aggregable: false,
  },
  {
    field: "recently_changed",
    headerName: "Recently Changed",
    width: SMALL_WIDTH,
    type: "boolean",
    aggregable: false,
  },
  {
    field: "value_trend",
    headerName: "Value Trend",
    width: 130,
    aggregable: false,
    filterOperators: withStringOperators,
    cellClassName: (params: GridCellParams) => {
      if (params.value == null) {
        return "";
      }

      return clsx("players", {
        negative: params.value === "decreasing",
        positive: params.value === "increasing",
      });
    },
  },
  {
    field: "mls_salary_est",
    headerName: "MLS Salary Estimate",
    width: MEDIUM_WIDTH,
    type: "number",
    sortable: true,
    filterable: true,
    aggregable: true,
    valueFormatter: (value, row, column, apiRef) => {
      return USDCurrencyFormatter.format(Math.ceil(Number(value) / 10) * 10);
    },
    filterOperators: withoutStringOperators,
  },
  {
    field: "position",
    headerName: "Position (TM)",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "secondary_position",
    headerName: "Secondary Position (SB)",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "agent",
    headerName: "Agent",
    width: MEDIUM_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "on_loan_from",
    headerName: "On Loan From",
    width: MEDIUM_WIDTH,
    aggregable: false,
  },
  {
    field: "on_loan_from_contract_expiration",
    headerName: "On Loan From Contract Expiration",
    aggregable: false,
    type: "date",
    width: MEDIUM_WIDTH,
    valueGetter: (value, row, column, apiRef) => {
      return new Date(value);
    },
  },
  {
    field: "np_psxg_90",
    headerName: STAT_INFO["np_psxg_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_xa_90",
    headerName: STAT_INFO["op_xa_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "gsaa_90",
    headerName: STAT_INFO["gsaa_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_shot_90",
    headerName: STAT_INFO["obv_shot_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_gk_90",
    headerName: STAT_INFO["obv_gk_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "touches_inside_box_90",
    headerName: STAT_INFO["touches_inside_box_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tackles_90",
    headerName: STAT_INFO["tackles_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "interceptions_90",
    headerName: STAT_INFO["interceptions_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "padj_tackles_and_interceptions_90",
    headerName: STAT_INFO["padj_tackles_and_interceptions_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
  },
  {
    field: "total_dribbles_90",
    headerName: STAT_INFO["total_dribbles_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "fouls_90",
    headerName: STAT_INFO["fouls_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "dispossessions_90",
    headerName: STAT_INFO["dispossessions_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_passes_90",
    headerName: STAT_INFO["op_passes_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_f3_passes_90",
    headerName: STAT_INFO["op_f3_passes_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "passing_ratio",
    headerName: STAT_INFO["passing_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_xgchain_90",
    headerName: STAT_INFO["op_xgchain_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_xgbuildup_90",
    headerName: STAT_INFO["op_xgbuildup_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "turnovers_90",
    headerName: STAT_INFO["turnovers_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "shot_touch_ratio",
    headerName: STAT_INFO["shot_touch_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "fouls_won_90",
    headerName: STAT_INFO["fouls_won_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "left_foot_ratio",
    headerName: STAT_INFO["left_foot_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "gsaa_ratio",
    headerName: STAT_INFO["gsaa_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "passes_pressed_ratio",
    headerName: STAT_INFO["passes_pressed_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "clcaa",
    headerName: STAT_INFO["clcaa"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "npxgxa_90",
    headerName: STAT_INFO["npxgxa_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "change_in_passing_ratio",
    headerName: STAT_INFO["change_in_passing_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "carry_length",
    headerName: STAT_INFO["carry_length"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "errors_90",
    headerName: STAT_INFO["errors_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "padj_pressures_90",
    headerName: STAT_INFO["padj_pressures_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "average_x_pass",
    headerName: STAT_INFO["average_x_pass"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "dc_lift_obv",
    headerName: "DC Lift OBV (computed)",
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    sortable: false,
    filterable: false,
  },
  {
    field: "pass_obv",
    headerName: "Pass OBV (computed)",
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    sortable: false,
    filterable: false,
  },
  {
    field: "pass_lift_obv",
    headerName: "Pass Lift OBV (computed)",
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    sortable: false,
    filterable: false,
  },
  {
    field: "shot_obv",
    headerName: "Shot OBV (computed)",
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    sortable: false,
    filterable: false,
  },
  {
    field: "tss_possession",
    headerName: STAT_INFO["tss_possession"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_ppda",
    headerName: STAT_INFO["tss_ppda"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_defensive_distance",
    headerName: STAT_INFO["tss_defensive_distance"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_fhalf_pressures_ratio",
    headerName: STAT_INFO["tss_fhalf_pressures_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_fhalf_counterpressures_ratio",
    headerName: STAT_INFO["tss_fhalf_counterpressures_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_np_xg_pg",
    headerName: STAT_INFO["tss_np_xg_pg"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_np_xg_conceded_pg",
    headerName: STAT_INFO["tss_np_xg_conceded_pg"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_directness",
    headerName: STAT_INFO["tss_directness"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_pace_towards_goal",
    headerName: STAT_INFO["tss_pace_towards_goal"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_passing_ratio",
    headerName: STAT_INFO["tss_passing_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tss_crosses_into_box_pg",
    headerName: STAT_INFO["tss_crosses_into_box_pg"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_avg_speed",
    headerName: STAT_INFO["pff_avg_speed"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_spt_pct",
    headerName: STAT_INFO["pff_spt_pct"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_hsr_pct",
    headerName: STAT_INFO["pff_hsr_pct"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_walk_p90",
    headerName: STAT_INFO["pff_walk_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_jog_p90",
    headerName: STAT_INFO["pff_jog_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_lsr_p90",
    headerName: STAT_INFO["pff_lsr_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_hsr_p90",
    headerName: STAT_INFO["pff_hsr_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_walkt_p90",
    headerName: STAT_INFO["pff_walkt_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_jogt_p90",
    headerName: STAT_INFO["pff_jogt_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_lsrt_p90",
    headerName: STAT_INFO["pff_lsrt_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_hsrt_p90",
    headerName: STAT_INFO["pff_hsrt_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_sprt_p90",
    headerName: STAT_INFO["pff_sprt_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_hsrc_p90",
    headerName: STAT_INFO["pff_hsrc_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_accel_p90",
    headerName: STAT_INFO["pff_accel_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_decel_p90",
    headerName: STAT_INFO["pff_decel_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
];

export const displayedByDefault: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    pinnable: false,
    aggregable: false,
    filterOperators: withStringOperators,
    renderCell: (params) => {
      // console.debug(params);
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Avatar
            src={params.row.image}
            sx={{ width: 32, height: 32, display: "flex", marginRight: 1 }}
          />
          {params.value}
        </div>
      );
    },
  },
  {
    field: "team",
    headerName: "Current Team (TM)",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
    pinnable: false,
  },
  {
    field: "birthdate",
    headerName: "Date of Birth",
    pinnable: false,
    width: SMALL_WIDTH,
    type: "date",
    aggregable: false,
    valueGetter: (value, row, column, apiRef) => {
      return getUTCDate(new Date(value));
    },
  },
  {
    field: "tm_value_num",
    headerName: "TM Value (€)",
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    valueFormatter: (value, row, column, apiRef) => {
      return EURCurrencyFormatter.format(Number(value));
    },
    filterOperators: withoutStringOperators,
  },
  {
    field: "tm_link",
    headerName: "TM Link",
    width: MEDIUM_WIDTH,
    aggregable: true,
    filterOperators: withoutStringOperators,
    renderCell: (params) => {
      return (
        <Link
          href={params.value}
          target="_blank"
          sx={{
            cursor: "pointer",
            textTransform: "none",
          }}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: "nationality",
    headerName: "Nationality",
    width: MEDIUM_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "league",
    headerName: "League",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "country",
    headerName: "League Country",
    width: MEDIUM_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "competition_name",
    headerName: "Competition",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "season_name",
    headerName: "Season",
    width: MEDIUM_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "team_name",
    headerName: "Team (SB)",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "primary_position",
    headerName: "Primary Position (SB)",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "age",
    headerName: "Age",
    width: SMALL_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "height",
    headerName: "Height",
    width: SMALL_WIDTH,
    aggregable: false,
  },
  {
    field: "foot",
    headerName: "Foot",
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "contract",
    headerName: "Contract",
    width: LARGE_WIDTH,
    aggregable: false,
    type: "date",
    valueGetter: (value, row, column, apiRef) => {
      return new Date(value);
    },
  },
  {
    field: "minutes",
    headerName: STAT_INFO["minutes"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_90",
    headerName: STAT_INFO["obv_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_pass_90",
    headerName: STAT_INFO["obv_pass_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_dribble_carry_90",
    headerName: STAT_INFO["obv_dribble_carry_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "obv_defensive_action_90",
    headerName: STAT_INFO["obv_defensive_action_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "npg_90",
    headerName: STAT_INFO["npg_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "np_xg_90",
    headerName: STAT_INFO["np_xg_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "shot_on_target_ratio",
    headerName: STAT_INFO["shot_on_target_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "np_shots_90",
    headerName: STAT_INFO["np_shots_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_assists_90",
    headerName: STAT_INFO["op_assists_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_key_passes_90",
    headerName: STAT_INFO["op_key_passes_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "crossing_ratio",
    headerName: STAT_INFO["crossing_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "op_passes_into_box_90",
    headerName: STAT_INFO["op_passes_into_box_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "dribbles_90",
    headerName: STAT_INFO["dribbles_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "dribble_ratio",
    headerName: STAT_INFO["dribble_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pressured_passing_ratio",
    headerName: STAT_INFO["pressured_passing_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "long_ball_ratio",
    headerName: STAT_INFO["long_ball_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "long_balls_90",
    headerName: STAT_INFO["long_balls_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "deep_progressions_90",
    headerName: STAT_INFO["deep_progressions_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pressures_90",
    headerName: STAT_INFO["pressures_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "tackles_and_interceptions_90",
    headerName: STAT_INFO["tackles_and_interceptions_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "aerial_ratio",
    headerName: STAT_INFO["aerial_ratio"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "padj_clearances_90",
    headerName: STAT_INFO["padj_clearances_90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_max_speed",
    headerName: STAT_INFO["pff_max_speed"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_tot_p90",
    headerName: STAT_INFO["pff_tot_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_spr_p90",
    headerName: STAT_INFO["pff_spr_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
  {
    field: "pff_sprc_p90",
    headerName: STAT_INFO["pff_sprc_p90"].elongated,
    width: MEDIUM_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
  },
];

const renderCellWithColor = (params: GridRenderCellParams) => {
  return <CellWithColor params={params} />;
};

interface AnalyticsDataGridColDefsProps {
  colDefs?: string[];
}

export default function AnalyticsDataGridColDefs(
  props: AnalyticsDataGridColDefsProps
) {
  const columns = useMemo(() => {
    return displayedByDefault
      .concat(hiddenByDefault)
      .map((col: GridColDef) => {
        if (!col.renderCell && col.type === "number") {
          col.renderCell = renderCellWithColor;
        }

        return col;
      })
      .filter(
        (col: GridColDef) =>
          props.colDefs &&
          (props.colDefs.includes(col.field) || props.colDefs.includes("*"))
      );
  }, [props.colDefs]);

  return columns;
}
