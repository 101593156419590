import { IconButton, Tooltip } from "@mui/material";

import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";

interface NavIconProps {
  name: string;
  obj: any;
  selected: boolean;
  setObjInContext: (obj: any) => void;
}

export const NavItemIcon = (props: NavIconProps) => {
  const color = props.selected ? "secondary" : "primary";

  return (
    <Tooltip
      title={props.selected ? `Deselect ${props.name}` : `Select ${props.name}`}
    >
      <IconButton
        key={props.name}
        size="small"
        color={color}
        onClick={(event: { stopPropagation: () => void }) => {
          if (!props.selected) {
            props.setObjInContext(props.obj);
          } else {
            props.setObjInContext(undefined);
          }
          event.stopPropagation();
        }}
        sx={{
          marginLeft: 1,
        }}
      >
        {props.selected && <CancelTwoToneIcon />}
        {!props.selected && <RadioButtonUncheckedRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
};
