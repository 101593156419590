import { Box, Fab, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import InfoIcon from "@mui/icons-material/Info";
import Players from "./Players";
import { usePlayerDatabaseMeta } from "../../api/queries";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

function Database() {
  // HOOKS
  const theme = useTheme();
  const showMatchRatio = useMediaQuery(theme.breakpoints.up("md"));

  // CONTEXT
  const { height } = useWindowSizeContext();

  // QUERIES
  const playerDatabaseMetaResult = usePlayerDatabaseMeta();

  return (
    <Box style={{ height: height - APPBAR_HEIGHT_AS_NUM - 5 }}>
      {showMatchRatio && (
        <Tooltip
          title={`most recent match: ${playerDatabaseMetaResult?.data?.most_recently_updated} | ratio: ${playerDatabaseMetaResult?.data?.ratio}%`}
        >
          <Fab
            sx={{
              position: "fixed",
              bottom: 10,
              left: 25,
              height: "0px",
              width: "0px",
            }}
            size="small"
            aria-label="close"
          >
            <InfoIcon color="primary" fontSize="medium" />
          </Fab>
        </Tooltip>
      )}

      <Players />
    </Box>
  );
}

export default Database;
