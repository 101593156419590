import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

export default function LoadingSpinnerOverlay() {
  return (
    <Box
      sx={{
        height: "50vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={60} thickness={2} />
    </Box>
  );
}
