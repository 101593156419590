/* eslint-disable no-eval */

export const ROOT_ROUTE = "/";
export const COACHES_CORNER_ROUTE = "/coaches";
export const IDP_ROUTE = "/idp";
export const LOGIN_CALLBACK_ROUTE = "/login/callback";
export const MATCH_REPORTS_ROUTE = "/match_reports";
export const NOTES_ROUTE = "/notes";
export const OPERATIONS_ROOT_ROUTE = "/operations";
export const REPORTS_ROUTE = "/reports";
export const SCOUTING_DEPTH_ROUTE = "/scouting/depth";
export const SCOUTING_PLAYERS_ROUTE = "/players";
export const SCOUTING_PLAYER_RATINGS_ROUTE = "/scouting/players/ratings";
export const SCOUTING_REPORTS_ROUTE = "/scouting_reports";
export const SCOUTING_ROOT_ROUTE = "/scouting";
export const WELCOME_ROUTE = "/welcome";
